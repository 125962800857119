import React, {useEffect, useState} from "react";
import {PagedResponse} from "../../../shared/paged-response";
import {useTranslation} from "react-i18next";
import {HttpClient} from "../../../shared/http-client";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
    Divider,
    FormControl,
    ListItemSecondaryAction
} from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import {Delete, Edit, Save} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import moment from "moment/moment";
import Button from "@mui/material/Button";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";
import {DeviceNote} from "../interfaces/DeviceNote";

export interface DeviceNotesProps {
    deviceId: string;
}

interface AddDeviceNoteRequest {
    text: string;
}

export function DeviceNotes({props}: { props: React.PropsWithChildren<DeviceNotesProps> }) : JSX.Element {
    const [response, setResponse] = useState<PagedResponse<Array<DeviceNote>>>();
    const [isLoading, setIsLoading] = useState(false);
    const [newNoteText, setNewNoteText] = useState<string>('');
    const {t} = useTranslation()
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(1000);
    const rowsPerPageOptions = [20, 40, 60];
    const servicesNs = 'services';
    const initialPageNumber = 0;

    const blue = {
        500: "#007FFF",
        600: "#0072E5",
        700: "#0059B2"
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
        load(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(initialPageNumber);
        load(pageNumber, newRowsPerPage);
    };

    function handleDocumentNoFilterChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setNewNoteText(event.target.value as string)
    }

    function saveDeviceNote() {
        let request: AddDeviceNoteRequest = {
             text: newNoteText
        }

        setIsLoading(true);

        HttpClient.post(`services/devices/${props.deviceId}/notes`, JSON.stringify(request))
            .then()
            .catch(_ => {console.debug(_)})
            .finally(() => {
                setNewNoteText('');
                load(pageNumber, rowsPerPage)
            })
    }

    function deleteNote(noteId: string) {
        setIsLoading(true);

        HttpClient.delete(`services/devices/${props.deviceId}/notes/${noteId}`)
            .then()
            .catch(_ => {console.debug(_)})
            .finally(() => {
                load(pageNumber, rowsPerPage)
            })
    }

    function load(page: number, pageSize: number) {
        setIsLoading(true);

        page += 1;
        let requestUrl = `services/devices/${props.deviceId}/notes?pageNumber=${page}&pageSize=${pageSize}`;

        HttpClient.get<PagedResponse<Array<DeviceNote>>>(requestUrl)
            .then(response => {
                setResponse(response);
            })
            .catch(_ => {console.debug(_)})
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        load(pageNumber, rowsPerPage)
    }, [])

    return(
        <>
            <Grid2 xs={12}>
                <Grid2 container justifyContent="flex-start">
                    <FormControl fullWidth>
                        {/*<InputLabel id="status-filter-select-label" size='small'>*/}
                        {/*    Add a note*/}
                        {/*</InputLabel>*/}
                        <TextField
                            id="req-remarks"
                            name="req-remarks"
                            //label={t("remarks", { ns: servicesNs })}
                            margin="dense"
                            rows={3}
                            multiline
                            fullWidth
                            inputProps={{ spellCheck: 'false' }}
                            value={newNoteText}
                            onChange={handleDocumentNoFilterChange}
                        />
                    </FormControl>
                </Grid2>
                <Grid2 container justifyContent="flex-end" sx={{ paddingTop: 0}}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={newNoteText.length === 0}
                        startIcon={<Save/>}
                        onClick={() => saveDeviceNote()}
                    >
                        {t("button_saveNote", { ns: servicesNs })}
                    </Button>
                </Grid2>

            </Grid2>
            <Grid2 xs={12} sx={{ paddingTop: 0}}>
                    <List sx={{ padding: 0}}>
                        {response?.data.map((item) =>
                            <>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        id={item.id}
                                        primary={item.text}
                                        secondary={moment.utc(item.createdAt).local().format('YYYY-MM-DD HH:mm') }
                                        sx={{paddingRight: 3}}
                                    />
                                    <ListItemSecondaryAction>
                                        {/*<IconButton>*/}
                                        {/*    <Edit />*/}
                                        {/*</IconButton>*/}
                                        <IconButton edge="end" aria-label="delete">
                                            <Delete onClick={() => deleteNote(item.id)}/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </>
                        )}
                    </List>
            </Grid2>

            <PortalCircularProgress isLoading={isLoading}/>

        </>
    )
}