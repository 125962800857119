
import { LoginConstants } from "../login/authentication/login-constants";

export class TokenService {

    public static setAccessToken(token: string) {
        window.localStorage.setItem(LoginConstants.ACCESS_TOKEN_KEY, token);
    }

    public static setRefreshToken(refreshToken: string) {
        window.localStorage.setItem(LoginConstants.REFRESH_TOKEN_KEY, refreshToken);
    }

    public static getAccessToken(): string | null {
        return window.localStorage.getItem(LoginConstants.ACCESS_TOKEN_KEY);
    }

    public static getRefreshToken(): string | null {
        return window.localStorage.getItem(LoginConstants.REFRESH_TOKEN_KEY);
    }

}