import IconButton from "@mui/material/IconButton";
import {AccountCircle} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {AuthenticationService} from "./authentication-service";
import { useNavigate } from 'react-router-dom';
import { Divider } from "@mui/material";

export function PortalAvatar() {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    let navigate = useNavigate();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOnClose = () => {
        setAnchorEl(null);
    }

    const handleChangePassword = () => {
        setAnchorEl(null);
        navigate(`/account/change-password`)
    };

    const handleLogout = () => {
        setAnchorEl(null);
        AuthenticationService.logout(true)
    };

    const handleTermsAndConditions= () => {
        setAnchorEl(null);
        window.open("/terms-and-conditions", "_blank")
    };

    const handleConsentsAndDeclarations = () => {
        setAnchorEl(null);
        navigate(`/consents-and-declarations`)
    }

    const handleOpenUserManual = () => {
        setAnchorEl(null);
        window.open("../user-manual.pdf", "_blank")
    }

    return(
        <div>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleOnClose}
            >
                <MenuItem onClick={handleChangePassword}>
                    {t("changePassword")}
                </MenuItem>
                <Divider/>
                <MenuItem onClick={handleTermsAndConditions} >
                    {t('termsAndConditions')}
                </MenuItem>
                <MenuItem onClick={handleConsentsAndDeclarations} >
                    {t("consentsAndDeclarations")}
                </MenuItem>
                <MenuItem onClick={handleOpenUserManual} >
                    {t("button_userManual")}
                </MenuItem>
                <Divider/>
                <MenuItem onClick={handleLogout}>
                    {t("logout")}
                </MenuItem>
            </Menu>
        </div>
    )
}