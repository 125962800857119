import Container from "@mui/material/Container";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React, {useEffect, useState} from "react";
import {PagedResponse} from "../../../shared/paged-response";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {HttpClient} from "../../../shared/http-client";
import {FormatDate} from "../../../Utils/utils";
import {TableFooter, TablePagination} from "@mui/material";
import {PortalCard} from "../../../shared/portal-card";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";

interface SubstituteDevice {
    id: string;
    slug: string;
    no: string;
    serialNo: string;
    name: string;
    name2: string;
    rentalDate: Date;
    returnDate: Date;
}

export function SubstituteDeviceList() {
    const [response, setResponse] = useState<PagedResponse<Array<SubstituteDevice>>>();
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const rowsPerPageOptions = [20, 40, 60];
    const servicesNs = 'services';
    const initialPageNumber = 0;

    useEffect(() => {
        load(pageNumber, rowsPerPage);
    }, [])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
        load(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(initialPageNumber);
        load(pageNumber, newRowsPerPage);
    };

    let navigate = useNavigate();

    function load(page: number, pageSize: number){
        setIsLoading(true);

        page += 1;
        let requestUrl = `services/substitute-devices/my-company?pageNumber=${page}&pageSize=${pageSize}`;

        HttpClient.get<PagedResponse<Array<SubstituteDevice>>>(requestUrl)
            .then(_ => {
                setResponse(_);
                setIsLoading(false);
            })
            .catch(_ => {
                setIsLoading(false);
            })
    }

    function showDetails(slug: string) {
        navigate(`/services/items/${slug}/details`);
    }

    return(
        <Container>
            <PortalCard
                title={t("substituteDevicesList", { ns: servicesNs }).toUpperCase()}
                children={
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 900}} align="left">{t("no", { ns: servicesNs })}</TableCell>
                                    <TableCell sx={{fontWeight: 900}} align="left">{t("serialNo", { ns: servicesNs })}</TableCell>
                                    <TableCell sx={{fontWeight: 900}} align="left">{t("description", { ns: servicesNs })}</TableCell>
                                    <TableCell sx={{fontWeight: 900}} align="left">{t("dateTimeLent", { ns: servicesNs })}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {response?.data.map((item) =>
                                    <TableRow
                                        key={item.id}
                                        hover={true}
                                    >
                                        <TableCell width="5%">{item.no}</TableCell>
                                        <TableCell width="10%">{item.serialNo}</TableCell>
                                        <TableCell width="70%">{item.name}</TableCell>
                                        <TableCell width="15%">{FormatDate(item.rentalDate)}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={response?.meta.totalRecords || 0}
                                        rowsPerPage={rowsPerPage}
                                        page={pageNumber}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        labelRowsPerPage={<span>Rows:</span>}
                                        labelDisplayedRows={({ page }) => {
                                            return `Page: ${page}`;
                                        }}
                                        backIconButtonProps={{
                                            color: "secondary"
                                        }}
                                        nextIconButtonProps={{ color: "secondary" }}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "page number"
                                            }
                                        }}
                                        showFirstButton={true}
                                        showLastButton={true}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                }
                />


            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}