import React, {useEffect, useState} from "react";
import {PagedResponse} from "../../../shared/paged-response";
import {useNavigate, useSearchParams} from "react-router-dom";
import {HttpClient} from "../../../shared/http-client";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment/moment";
import {
    Backdrop,
    CircularProgress,
    FormControl,
    InputLabel,
    Select, SelectChangeEvent,
    Stack,
    TableFooter,
    TablePagination
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {PortalCard} from "../../../shared/portal-card";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";
import AddIcon from '@mui/icons-material/Add';
import Grid2 from "@mui/material/Unstable_Grid2";
import MenuItem from "@mui/material/MenuItem";
import {StatusChip} from "../../../shared/status-chip";

interface Product {
    id: string;
    slug: string;
    no: string;
    no2: string;
    name: string;
    name2: string;
}

export function ProductsList() {
    const [response, setResponse] = useState<PagedResponse<Array<Product>>>();
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation()
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const rowsPerPageOptions = [20, 40, 60];
    const initialPageNumber = 0;
    const catalogNs = "catalogs";
    let navigate = useNavigate();

    const handleChangeCategoryFilter = (event: SelectChangeEvent<typeof categoryFilter>) => {
        const {
            target: { value },
        } = event;
        setCategoryFilter(
            typeof value === 'string' ? value.split(',') : value
        );
        //getServiceOrderRequests(pageNumber, rowsPerPage, referenceNoFilter, (value as Array<string>).join(','), orderBy)
    };

    useEffect(() => {
        loadProducts(pageNumber, rowsPerPage, '');
    }, [])

    function loadProducts(page: number, pageSize: number, statusFilter: string | null) {
        setIsLoading(true);

        page += 1;

        let requestUrl = `catalog/products?pageNumber=${page}&pageSize=${pageSize}`;

        if(categoryFilter) requestUrl += `&category=${categoryFilter}`

        HttpClient.get<PagedResponse<Array<Product>>>(requestUrl)
            .then(_ => {
                setResponse(_);
                setIsLoading(false);
            })
            .catch(_ => {
                setIsLoading(false);
            })
    }

    function showProductCard(slug: string) {
        navigate(``);
    }

    function createQuoteRequest(id: string, no: string) {
        navigate(`/quotes/quote-requests?createNewQuoteRequest=1&productNo=${no}&productId=${id}`,);
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
        loadProducts(newPage, rowsPerPage, '');
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(initialPageNumber);
        loadProducts(pageNumber, newRowsPerPage, '');
    };

    function downloadProductCatalog() {
        HttpClient.get(`catalog/products/file`)
            .then(_ => {
                const csvContent = `data:text/csv;charset=utf-8,${_}`;
                const fileName = `Product Catalog - ${moment().format("YYYY-MM-DD HH:mm")}.csv`;
                saveAs(csvContent, fileName)
            })
            .catch(_ => {
            })
    }

    function saveAs(uri: string, filename: string) {
        const link = document.createElement('a');
        if (typeof link.download === 'string') {
            link.href = uri;
            link.download = filename;

            //Firefox requires the link to be in the body
            document.body.appendChild(link);

            //simulate click
            link.click();

            //remove the link when done
            document.body.removeChild(link);
        } else {
            window.open(uri);
        }
    }

    return(
        <Container>
            <PortalCard
                title={t("productList", { ns: catalogNs })}
                children={
                    <Box>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} sm={12}>
                                <Grid2 container>
                                    {/*<FormControl fullWidth>*/}
                                    {/*    <InputLabel id="status-filter-select-label" size='small'>*/}
                                    {/*        Status*/}
                                    {/*    </InputLabel>*/}
                                    {/*    <Select*/}
                                    {/*        labelId="status-filter-select-label"*/}
                                    {/*        id="status-filter-select"*/}
                                    {/*        multiple*/}
                                    {/*        value={categoryFilter}*/}
                                    {/*        label="Status"*/}
                                    {/*        variant="outlined"*/}
                                    {/*        onChange={handleChangeCategoryFilter}*/}
                                    {/*        size='small'*/}
                                    {/*    >*/}
                                    {/*        {statuses.map((status) => (*/}
                                    {/*            <MenuItem*/}
                                    {/*                key={status}*/}
                                    {/*                value={status}*/}
                                    {/*            >*/}
                                    {/*                <StatusChip props={{status : status!}}/>*/}
                                    {/*            </MenuItem>*/}
                                    {/*        ))}*/}
                                    {/*    </Select>*/}
                                    {/*</FormControl>*/}
                                </Grid2>
                            </Grid2>

                            <Grid2 xs={12} sm={12}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t("no", { ns: catalogNs })}</TableCell>
                                                <TableCell>{t("name", { ns: catalogNs })}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {response?.data.map((item) =>
                                                <TableRow
                                                    key={item.id}
                                                    hover={true}
                                                >
                                                    <TableCell>{item.no}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell align="right">
                                                        <Button
                                                            variant="contained"
                                                            color="success"
                                                            size="small"
                                                            startIcon={<AddIcon/>}
                                                            onClick={() => createQuoteRequest(item.id, item.no)}
                                                        >
                                                            {t("button_createQuoteRequest")}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    count={response?.meta.totalRecords || 0}
                                                    rowsPerPage={rowsPerPage}
                                                    page={pageNumber}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                    labelRowsPerPage={<span>Rows:</span>}
                                                    labelDisplayedRows={({ page }) => {
                                                        return `Page: ${page}`;
                                                    }}
                                                    backIconButtonProps={{
                                                        color: "secondary"
                                                    }}
                                                    nextIconButtonProps={{ color: "secondary" }}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "page number"
                                                        }
                                                    }}
                                                    showFirstButton={true}
                                                    showLastButton={true}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid2>
                        </Grid2>
                    </Box>
                }
            />

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}