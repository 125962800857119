import React, {useEffect, useState} from "react";
import {HttpClient} from "../../../shared/http-client";
import Grid2 from "@mui/material/Unstable_Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import {Divider, ListItemSecondaryAction} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {StatusChip} from "../../../shared/status-chip";
import {FormatTotal} from "../../../Utils/utils";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

export interface DeviceUsageProps {
    deviceId: string;
}

export interface WhereDeviceWasUsed {
    serviceOrders: DeviceUsage[];
}

export interface DeviceUsage {
    id: string;
    slug: string;
    number: string;
    orderDateTime: Date;
}

export function DeviceUsage({props}: { props: React.PropsWithChildren<DeviceUsageProps> }) : JSX.Element {
    const [response, setResponse] = useState<WhereDeviceWasUsed>();
    const [isLoading, setIsLoading] = useState(false);
    const servicesNs = 'services';
    let navigate = useNavigate();

    function load() {
        setIsLoading(true);
        HttpClient.get<WhereDeviceWasUsed>(`services/devices/${props.deviceId}/usage`)
            .then(response => setResponse(response))
            .catch(_ => {console.debug(_)})
            .finally(() => setIsLoading(false));
    }

    function showServiceOrder(serviceOrderSlug: string) {
        navigate(`/services/service-orders/${serviceOrderSlug}`,);
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <>
            <Grid2 xs={12} sx={{ paddingTop: 0}}>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {response?.serviceOrders.map((item) =>
                                <TableRow
                                    onClick={() => showServiceOrder(item.slug)}
                                    key={item.id}
                                    hover={true}
                                >
                                    <TableCell>{item.number}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid2>
        </>
    )
}