import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {StatusChip} from "../../shared/status-chip";
import {Button} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import React, {useEffect, useState} from "react";
import {HttpClient} from "../../shared/http-client";
import {PagedResponse} from "../../shared/paged-response";
import {Device} from "../services/interfaces/Device";
import {useTranslation} from "react-i18next";
import {FormatDate} from "../../Utils/utils";
import {useNavigate} from "react-router-dom";

interface SubstituteDevice {
    id: string;
    slug: string;
    no: string;
    serialNo: string;
    name: string;
    rentalDate: Date;
}

export function SubstituteDevicesPart() {
    const [response, setResponse] = useState<PagedResponse<Array<SubstituteDevice>>>();
    const {t} = useTranslation();
    const servicesNs = 'services';
    let navigate = useNavigate();

    function ShowDetails(serviceOrderSlug: string) {
        navigate(`/services/substitute-devices/${serviceOrderSlug}`);
    }

    function ShowAll() {
        navigate(`/services/substitute-devices`);
    }

    function load() {
        let requestUrl = `services/substitute-devices/my-company?pageNumber=${1}&pageSize=${5}`;

        HttpClient.get<PagedResponse<Array<SubstituteDevice>>>(requestUrl)
            .then(response => {
                setResponse(response);
            })
            .catch(_ => {
            })
    }

    useEffect(() => {
        load();
    }, [])

    return(
        <Box
            bgcolor="white"
            sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                height: 300,
                border: 1,
                borderColor: "#DDE2E9"
            }}
        >
            <Box bgcolor="primary.main">
                <Typography
                    component="h6"
                    variant="h6"
                    color="white"
                    sx={{p: 1}}
                >
                    {t("substituteDevicesList", { ns: servicesNs }).toUpperCase()}
                </Typography>
            </Box>
            <Box sx={{p: 0}}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell width="30%" sx={{fontWeight: 900}} align="left">{t("no", { ns: servicesNs })}</TableCell>
                                <TableCell width="40%" sx={{fontWeight: 900}} align="left">{t("serialNo", { ns: servicesNs })}</TableCell>
                                <TableCell width="30%" sx={{fontWeight: 900}} align="left">{t("dateTimeLent", { ns: servicesNs })}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {response?.data.slice(0,5).map((item) =>
                                <TableRow
                                    key={item.id}
                                    hover={true}
                                    onClick={() => ShowDetails(item.slug)}
                                >
                                    <TableCell>{item.no}</TableCell>
                                    <TableCell>{item.serialNo}</TableCell>
                                    <TableCell>{FormatDate(item.rentalDate)}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container
                      justifyContent="flex-end"
                      padding={1}>
                    <Link underline="none"
                          color="primary"
                          onClick={() => ShowAll()}>
                        {t("viewAll")}
                    </Link>
                </Grid>
            </Box>
        </Box>
    )
}