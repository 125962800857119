import React, {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {HttpClient} from "../../../shared/http-client";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Divider, Stack} from "@mui/material";
import {FormatDate} from "../../../Utils/utils";
import PostAddIcon from '@mui/icons-material/PostAdd';
import {StatusChip} from "../../../shared/status-chip";

interface QuoteRequestDetails {
    id: string;
    slug: string;
    number: string;
    referenceNumber: string;
    name: string;
    quoteRequestDate: Date;
    requestedDeliveryDate: Date;
    status: string;
    statusUpdatedAt: Date;
    buyerRemarks: string;
    sellerRemarks: string;
    contactPersonName: string;
    contactPersonEmail: string;
    contactPersonPhoneNumber: string;
    contactInformation: string;
    createdAt: Date;
    updatedAt: Date;
    items: QuoteRequestDetailsItem[];
}

interface QuoteRequestDetailsItem {
    lineNumber: number;
    lineIdentifier: string;
    productId: string;
    productNo: string;
    productSlug: string;
    description: string;
    quantity: number;
    unitOfMeasureCode: string;
    unitPrice: number;
    amount: number;
    vatAmount: number;
    amountInclVat: number;
    buyerRemarks: string;
    sellerRemarks: string;
    requestedDeliveryDate: Date;
    promisedDeliveryDate: Date;
    createdAd: Date;
    updatedAt: Date;
}

export function QuoteRequestDetails() {
    const [quoteRequestDetails, setQuoteRequestDetails] = useState<QuoteRequestDetails>();
    const [isLoading, setIsLoading] = useState(false);
    const [t] = useTranslation();
    const { slug } = useParams();
    const quotesNs: string = 'quotes';

    function reject(id: string) {
        setIsLoading(true);
        HttpClient.post(`quotes/quote-requests/${id}/reject`, '')
            .then(_ => {console.log('sukces')})
            .catch(_ => console.log(_))
            .finally(() => {
                loadQuoteRequestDetails();
                setIsLoading(true)
            });
    }

    function approve(id: string) {
        setIsLoading(true);
        HttpClient.post(`quotes/quote-requests/${id}/approve`, '')
            .then()
            .catch()
            .finally(() => {
                loadQuoteRequestDetails();
                setIsLoading(true)
            });
    }

    function convertToOrder(id: string) {
        setIsLoading(true);
        HttpClient.post(`quotes/quote-requests/${id}/convert-to-order`, '')
            .then()
            .catch()
            .finally(() => {
                loadQuoteRequestDetails();
                setIsLoading(true)
            });
    }

    function loadQuoteRequestDetails() {
        setIsLoading(true);
        HttpClient.get<QuoteRequestDetails>(`quotes/quote-requests/${slug}`)
            .then(_ => {
                setQuoteRequestDetails(_);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        loadQuoteRequestDetails();
    }, [])

    return(
        <Container>
            <Grid container justifyContent="flex-end">
                <Box sx={{mb: 2}}>
                    <Stack spacing={2} direction="row">
                        <Button
                            variant="contained"
                            color="error"
                            size="medium"
                            startIcon={<PostAddIcon/>}
                            disabled={quoteRequestDetails?.status.toUpperCase() !== "PENDING APPROVAL"}
                            onClick={() => reject(quoteRequestDetails?.id!)}
                        >
                            {t("button_reject")}
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            size="medium"
                            startIcon={<PostAddIcon/>}
                            disabled={quoteRequestDetails?.status.toUpperCase() !== "PENDING APPROVAL"}
                            onClick={() => approve(quoteRequestDetails?.id!)}
                        >
                            {t("button_approve")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            startIcon={<PostAddIcon/>}
                            disabled={quoteRequestDetails?.status.toUpperCase() !== "APPROVED"}
                             onClick={() => convertToOrder(quoteRequestDetails?.id!)}
                        >
                            {t("button_convertToOrder")}
                        </Button>
                    </Stack>
                </Box>
            </Grid>
            <Grid>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                    <Grid container spacing={2} >
                        <Grid container item xs={6} direction="column" >
                            <Typography>
                                <strong>{t("number", { ns: quotesNs })}:</strong> {quoteRequestDetails?.number}
                            </Typography>
                            <Typography>
                                <strong>{t("referenceNumber", { ns: quotesNs })}:</strong> {quoteRequestDetails?.referenceNumber}
                            </Typography>
                            <Typography>
                                <strong>{t("quoteRequestDate", { ns: quotesNs })}: </strong> {FormatDate(quoteRequestDetails?.quoteRequestDate)}
                            </Typography>
                            <Typography>
                                <strong>{t("requestedDeliveryDate", { ns: quotesNs })}: </strong> {FormatDate(quoteRequestDetails?.requestedDeliveryDate)}
                            </Typography>
                            <Typography>
                                <strong>{t("status", { ns: quotesNs })}:</strong> <StatusChip props={{status: quoteRequestDetails?.status!}} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <br/>

                    <Grid>
                        <Typography>
                            <strong>{t("name", { ns: quotesNs })}:</strong>
                        </Typography>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {quoteRequestDetails?.name}
                        </Typography>
                    </Grid>
                    <br/>

                    <Grid container item xs={6} direction="column" >
                        <Typography>
                            <strong>{t("contactPerson", { ns: quotesNs })}:</strong>
                        </Typography>
                        <Typography>
                            {quoteRequestDetails?.contactPersonName}
                        </Typography>
                        <Typography>
                            {quoteRequestDetails?.contactPersonPhoneNumber}
                        </Typography>
                        <Typography>
                            {quoteRequestDetails?.contactPersonEmail}
                        </Typography>
                        <Typography>
                            {quoteRequestDetails?.contactInformation}
                        </Typography>
                    </Grid>
                    <br/>

                    <Grid>
                        <Typography>
                            <strong>{t("remarks", { ns: quotesNs })}:</strong>
                        </Typography>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {quoteRequestDetails?.buyerRemarks}
                        </Typography>
                    </Grid>
                    <br/>

                    <Grid>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20%"><strong>{t("no", { ns: quotesNs })}</strong></TableCell>
                                        <TableCell width="40%"><strong>{t("description", { ns: quotesNs })}</strong></TableCell>
                                        <TableCell width="15%"><strong>{t("quantity", { ns: quotesNs })}</strong></TableCell>
                                        <TableCell width="10%"><strong>{t("unitOfMeasure", { ns: quotesNs })}</strong></TableCell>
                                        <TableCell width="15%"><strong>{t("amountInclVat", { ns: quotesNs })}</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {quoteRequestDetails?.items.map((item) =>
                                        <TableRow
                                            key={item?.lineNumber}
                                        >
                                            <TableCell>{item?.productNo}</TableCell>
                                            <TableCell>{item?.description}</TableCell>
                                            <TableCell>{item?.quantity}</TableCell>
                                            <TableCell>{item?.unitOfMeasureCode}</TableCell>
                                            <TableCell>{item?.amountInclVat}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    )
}