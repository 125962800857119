import { ReactComponent as Logo} from "../logo.svg";
import {styled} from "@mui/material/styles";

type Props = {
    height: number
}

export function PortalLogo({height} : Props) {
    const PortalLogo = styled(Logo)({
         height: height,
    }) as typeof Logo;

    return(
        <PortalLogo />
    )
}
