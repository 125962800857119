import Container from "@mui/material/Container";
import {PortalCard} from "../../shared/portal-card";
import Box from "@mui/material/Box";
import * as React from "react";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export function ConsentsAndDeclarations() {
    const {t, } = useTranslation()

    const [openRegulationsOfTheCustomerPortal, setOpenRegulationsOfTheCustomerPortal] = React.useState(false);
    const [openPersonalData, setOpenPersonalData] = React.useState(false);
    const [openRepresentationAndEmpowerment, setOpenRepresentationAndEmpowerment ] = React.useState(false);
    const [openInformationClause, setOpenInformationClause] = React.useState(false);
    const handleOpenRegulationsOfTheCustomerPortal = () => {setOpenRegulationsOfTheCustomerPortal(true);};
    const handleCloseRegulationsOfTheCustomerPortal = () => {setOpenRegulationsOfTheCustomerPortal(false);};
    const handleOpenPersonalData = () => {setOpenPersonalData(true);};
    const handleClosePersonalData = () => {setOpenPersonalData(false);};
    const handleOpenRepresentationAndEmpowerment = () => {setOpenRepresentationAndEmpowerment(true);};
    const handleCloseRepresentationAndEmpowerment = () => {setOpenRepresentationAndEmpowerment(false);};
    const handleOpenInformationClause = () => {setOpenInformationClause(true);};
    const handleCloseInformationClause = () => {setOpenInformationClause(false);};

    return(
        <Container>
            <PortalCard
                title={t("consentsAndDeclarations").toUpperCase()}
                children={
                    <Box sx={{ p: 3 }}>
                    <Typography>
                        <Typography variant="h5">ZGODY</Typography>
                        <h1>{openRegulationsOfTheCustomerPortal}</h1>
                        <Typography>
                            <ol type="1">
                                <li>Regulamin Portalu Klienta <Button variant="text" onClick={handleOpenRegulationsOfTheCustomerPortal}>[ZOBACZ TREŚĆ]</Button></li>
                                <li>Dane osobowe <Button variant="text" onClick={handleOpenPersonalData}>[ZOBACZ TREŚĆ]</Button></li>
                            </ol>
                        </Typography>
                        <Typography variant="h5">OŚWIADCZENIA</Typography>
                        <Typography>
                            <ol type="1">
                                <li>Reprezentacja i umocowanie <Button variant="text" onClick={handleOpenRepresentationAndEmpowerment}>[ZOBACZ TREŚĆ]</Button></li>
                                <li>Klauzula informacyjna <Button variant="text" onClick={handleOpenInformationClause}>[ZOBACZ TREŚĆ]</Button></li>
                            </ol>
                        </Typography>
                    </Typography>

                    </Box>
                }
            />

            <Dialog onClose={handleCloseRegulationsOfTheCustomerPortal} open={openRegulationsOfTheCustomerPortal}>
                <DialogContent dividers>
                    <Typography>
                        Oświadczam, że zapoznałem się z treścią Regulaminu, akceptuję jego postanowienia i zobowiązuje się do jego przestrzegania.
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClosePersonalData} open={openPersonalData}>
                <DialogContent dividers>
                    <Typography>
                        Wyrażam zgodę na przetwarzanie przez Becker Polska sp. z o.o. Pianowo 46, 64-000 Kościan moich danych osobowych umieszczonych w Portalu Klienta Becker Polska sp. z o.o.
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleCloseRepresentationAndEmpowerment} open={openRepresentationAndEmpowerment}>
                <DialogContent dividers>
                    <Typography>
                        Oświadczam, że działam jako skutecznie umocowany reprezentant albo pełnomocnik Klienta i jestem upoważniony do składania w jego imieniu wszelkich oświadczeń woli i wiedzy, w tym zaciągania zobowiązań.
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleCloseInformationClause} open={openInformationClause}>
                <DialogContent dividers>
                    <Typography>
                        Oświadczam, że zapoznałem się z treścią klauzuli informacyjnej.
                    </Typography>
                </DialogContent>
            </Dialog>

        </Container>
    )
}