
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Backdrop, CircularProgress, Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {FormEvent, useState} from "react";
import {HttpClient} from "../../shared/http-client";
import {useTranslation} from "react-i18next";
import {PortalCard} from "../../shared/portal-card";
import {KeyboardArrowRight} from "@mui/icons-material";
import {AuthenticationService} from "../../shared/authentication-service";
import Container from "@mui/material/Container";
interface ChangePasswordRequest {
    currentPassword: string;
    newPassword: string;
}

export function ChangePassword() {
    const [formValues, setFormValues] = useState({
        currentPassword: {
            value: "",
            error: false,
            errorMessage: ""
        },
        newPassword: {
            value: "",
            error: false,
            errorMessage: ""
        },
        newPasswordRepeated: {
            value: "",
            error: false,
            errorMessage: ""
        },
    })
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {t, } = useTranslation()

    function handleCloseSnackbar(event?: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorSnackbar(false);
    }

    function validateFormValues() : boolean {
        setErrorMessage('');

        formValues.currentPassword.error = false;
        formValues.currentPassword.errorMessage = '';
        formValues.newPassword.error = false;
        formValues.newPassword.errorMessage = '';
        formValues.newPasswordRepeated.error = false;
        formValues.newPasswordRepeated.errorMessage = '';

        if (formValues.currentPassword.value === '') {
            formValues.currentPassword.error = true;
            formValues.currentPassword.errorMessage = 'Musisz podac hasło!';
        }

        if (formValues.newPassword.value === '') {
            formValues.newPassword.error = true;
            formValues.newPassword.errorMessage = 'Musisz podac nowe hasło!';
        }

        if (!formValues.newPasswordRepeated.value ) {
            formValues.newPasswordRepeated.error = true;
            formValues.newPasswordRepeated.errorMessage = t("message_repeatedPasswordEmpty");
        }

        if (formValues.newPassword.value && formValues.newPasswordRepeated.value && (formValues.newPassword.value !== formValues.newPasswordRepeated.value)) {
            formValues.newPassword.error = true;
            formValues.newPassword.errorMessage = t("message_newPasswordAndNewPasswordRepeatedDoNotMatch");
            formValues.newPasswordRepeated.error = true;
            formValues.newPasswordRepeated.errorMessage = t("message_newPasswordAndNewPasswordRepeatedDoNotMatch");
        }

        if (formValues.currentPassword.value && formValues.newPassword.value && formValues.currentPassword.value === formValues.newPassword.value) {
            formValues.newPassword.error = true;
            formValues.newPassword.errorMessage = t("message_currentPasswordAndNewPasswordMatch");
        }

        setFormValues(formValues);

        return !(formValues.currentPassword.error || formValues.newPassword.error || formValues.newPasswordRepeated.error);


    }

    function handleInputChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const {name, value} = event.target;
        setFormValues({
            ...formValues,
            [name]:{
                value: value,
                error: false,
                errorMessage: ""
            }
        });
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if(!validateFormValues()) {
            return;
        }

        const request: ChangePasswordRequest = {
            currentPassword: formValues.currentPassword.value,
            newPassword: formValues.newPassword.value
        };

        setErrorMessage('');
        setIsLoading(true);

        HttpClient.post('user-access/account/change-password', JSON.stringify(request))
            .then(() => {
                AuthenticationService.logout(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data.Errors[0]);
                setOpenErrorSnackbar(true);
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <Container>
            <PortalCard
                title={t("page_changePassword").toUpperCase()}
                children={
                    <Box
                        sx={{ p: 3 }}>
                        <form
                            onSubmit={handleSubmit}
                            noValidate
                        >

                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                id="currentPassword"
                                label={t("password")}
                                name="currentPassword"
                                type="password"
                                value={formValues.currentPassword.value}
                                error={formValues.currentPassword.error}
                                helperText={formValues.currentPassword.error && formValues.currentPassword.errorMessage}
                                onChange={handleInputChange}
                                sx={{ mt: 1 }}
                            />
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                id="newPassword"
                                label={t("newPassword")}
                                name="newPassword"
                                type="password"
                                value={formValues.newPassword.value}
                                error={formValues.newPassword.error}
                                helperText={formValues.newPassword.errorMessage}
                                onChange={handleInputChange}
                                sx={{ mt: 1 }}
                            />
                            <TextField
                                required
                                fullWidth
                                id="newPasswordRepeated"
                                label={t("newPasswordRepeated")}
                                name="newPasswordRepeated"
                                type="password"
                                value={formValues.newPasswordRepeated.value}
                                error={formValues.newPasswordRepeated.error}
                                helperText={formValues.newPasswordRepeated.errorMessage}
                                onChange={handleInputChange}
                                sx={{ mt: 1 }}
                            />
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                endIcon={<KeyboardArrowRight />}
                            >
                                {t("button_change")}
                            </Button>

                            <Snackbar open={openErrorSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                                <MuiAlert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                                    {errorMessage}
                                </MuiAlert>
                            </Snackbar>

                        </form>

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                    </Box>
                }
            />
        </Container>
    );


}