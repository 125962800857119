import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";


type Props = {
    title: string,
    children: JSX.Element
}

export function PortalCard({title, children}: Props) {
    return(
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            border: 1,
            borderColor: "#DDE2E9"
        }}>
            <Box
                bgcolor="primary.main"
            >
                <Typography
                    component="h6"
                    variant="h6"
                    color="white"
                    sx={{
                        p: 1
                    }}
                >
                    {title}
                </Typography>
            </Box>
            <Box
                sx={{
                    p: 0,
                    backgroundColor: 'white'
                }}>
                {children}
            </Box>
        </Box>
    )
}