import React from "react";
import {styled} from "@mui/material/styles";
import {Chip} from "@mui/material";
import {useTranslation} from "react-i18next";

interface  QuoteStatusBadgeProps {
    status: string;
}

export function StatusChip({props}: { props: React.PropsWithChildren<QuoteStatusBadgeProps> }) : JSX.Element {
    const [t] = useTranslation();
    const StatusChip = styled(Chip)({
        "&.MuiChip-root": {
            maxWidth: "100%",
            fontFamily: "Roboto, Helvetica, Arial,sans-serif",
            fontSize: "0.7125rem",
            fontWeight: 400,
            height: "20px",
            color: "white",
            borderRadius: "14px",
            overflow: 'hidden',
            whiteSpace: "nowrap",
            display: "inline-flex",
            cursor: "default",
            outline: 0,
            texDecoration: "none",
            border: 0,
            padding: 0,
            verticalAlign: "middle",
            boxSizing: "border-box",
            backgroundColor: GetBadgeColor(props.status)
        }
    });

    function GetBadgeColor(status: string) {
        if(status) {
            switch (status.toUpperCase()) {
                case "OWN" :
                case "NEW" :
                    return "#607d8b"
                    break;
                case "PENDING APPROVAL" :
                case "WAITING FOR SERVICE ITEM" :
                    return "#1976d2";
                    break;
                case "UNPAID":
                case "IN PROCESS":
                case "TEMPRARILY INSTALLED" :
                    return "#1976d2";
                    break;
                case "PAID" :
                case "GUARANTEE" :
                case "APPROVED" :
                case "ACCEPTED" :
                case "INSTALLED":
                    return "#2e7d32";
                    break;
                case "OVERDUE" :
                case "REJECTED" :
                    return "#d32f2f";
                    break;
                case "CANCELED" :
                case "LACK" :
                    return "#d32f2f";
                    break;
                case "ORDERED" :
                case "FINISHED" :
                    return "#0288d1";
                    break;
                case "PARTIALY PAID" :
                case "EXPIRED" :
                case "DEFECTVE" :
                case "DISABLED" :
                    return "#ed6c02";
                    break;
                case "" :
                    return "#fff";
                    break;
            }
        }
        return "grey";
    }

    function TranslateStatus(status: string ) {
        if(status) {
            let translationKey : string = props.status.replace(/\s+/g, '_').toLowerCase();
            return t(translationKey, { ns: "statuses"})
        }
        return status;
    }

    return(
        <StatusChip label={TranslateStatus(props.status)}></StatusChip>
    )
}