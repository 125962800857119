
import Container from '@mui/material/Container'
import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import {HttpClient} from "../../../shared/http-client";
import {PagedResponse} from "../../../shared/paged-response";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useTranslation} from "react-i18next";
import {Box,
        TableFooter,
        TablePagination
} from "@mui/material";
import {StatusChip} from "../../../shared/status-chip";
import {PortalCard} from "../../../shared/portal-card";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";
import {FormatDate} from "../../../Utils/utils";
import {Device} from "../interfaces/Device";



export function DeviceList() {
    const [response, setResponse] = useState<PagedResponse<Array<Device>>>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {t} = useTranslation()
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const rowsPerPageOptions = [20, 40, 60];
    const servicesNs = 'services';
    const initialPageNumber = 0;

    useEffect(() => {
        load(pageNumber, rowsPerPage);
    }, [])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
        load(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(initialPageNumber);
        load(pageNumber, newRowsPerPage);
    };

    let navigate = useNavigate();

    function load(page: number, pageSize: number) {
        setIsLoading(true);

        page += 1;
        let requestUrl = `services/devices/my-company?pageNumber=${page}&pageSize=${pageSize}`;

        HttpClient.get<PagedResponse<Array<Device>>>(requestUrl)
            .then(response => {
                setResponse(response);
                setIsLoading(false);
            })
            .catch(_ => {
                setIsLoading(false);
            })
    }

    function showDetails(slug: string) {
        navigate(`/services/devices/${slug}`);
    }



    return(
        <Container>
            <PortalCard
                title={t("serviceItemsList", { ns: servicesNs }).toUpperCase()}
                children={
                <Box>
                    <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: 900 }}>{t("no", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900 }}>{t("serialNo", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900 }}>{t("status", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900 }}>{t("name", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900 }}>{t("lastServiceDate", { ns: servicesNs})}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {response?.data.map((item) =>
                                            <TableRow
                                                onClick={() => showDetails(item.slug)}
                                                key={item.id}
                                                hover={true}
                                            >
                                                <TableCell>{item.no}</TableCell>
                                                <TableCell>{item.serialNo}</TableCell>
                                                <TableCell>{ item?.status && <StatusChip props={{status : item?.status!}}/>}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{FormatDate(item.lastServiceDate)}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                count={response?.meta.totalRecords || 0}
                                                rowsPerPage={rowsPerPage}
                                                page={pageNumber}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                rowsPerPageOptions={rowsPerPageOptions}
                                                labelRowsPerPage={<span>Rows:</span>}
                                                labelDisplayedRows={({ page }) => {
                                                    return `Page: ${page}`;
                                                }}
                                                backIconButtonProps={{
                                                    color: "secondary"
                                                }}
                                                nextIconButtonProps={{ color: "secondary" }}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "page number"
                                                    }
                                                }}
                                                showFirstButton={true}
                                                showLastButton={true}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                </Box>

                }
            />

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}