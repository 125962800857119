import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {PortalCard} from "../../../shared/portal-card";
import Container from "@mui/material/Container";


export function MyCompany() {
    return (
        <Container>
            <PortalCard
                title="My company"
                children={
                    <Box>

                    </Box>
                }
            />
        </Container>
    )
}