import React, {Component} from 'react';
import './App.css';

import "./fonts/MuseoSans-100.otf"
import "./fonts/MuseoSans-300.otf"
import "./fonts/MuseoSans-500.otf"
import "./fonts/MuseoSans-700.otf"

import {
  Routes,
  Route,
  Navigate,
  Outlet
} from "react-router-dom";

import { LoginForm } from './login/login-form';
import { AuthenticationService } from './shared/authentication-service';
import { Dashboard } from './modules/dashboard/dashboard';
import { MyCompany } from './modules/customers/my-company/my-company';
import { QuotesList } from './modules/documents/quotes/quotes-list';
import { OrderList } from './modules/documents/orders/order-list';
import { InvoiceList } from './modules/documents/invoices/invoice-list';
import {ServiceOrderList} from "./modules/services/service-orders/service-order-list";
import {ServiceOrderDetails} from "./modules/services/service-orders/service-order-details";
import {PortalLayout} from "./shared/portal-layout";
import {ProductsList} from "./modules/catalog/products/products-list";
import {ServiceInvoiceList} from "./modules/services/service-invoices/service-invoice-list";
import {SubstituteDeviceList} from "./modules/services/substitute-devices/substitute-device-list";
import {ServiceOrderRequestList} from "./modules/services/service-order-requests/ServiceOrderRequestList";
import {CreditMemoList} from "./modules/documents/credit-memos/credit-memo-list";
import {RegistrationConfirmation} from "./modules/account/registration-confirmation";
import {ForgotPassword} from "./modules/account/forgot-password";
import {ChangePassword} from "./modules/account/change-password";
import {ActivateAccount} from "./modules/account/activate-account";
import {QuoteRequestList} from "./modules/quotes/quote-requests/quote-request-list";
import {QuoteRequestDetails} from "./modules/quotes/quote-requests/quote-request-details";
import {MyOrdersList} from "./modules/orders/orders/my-order-list";
import {OrderDetails} from "./modules/orders/orders/order-details";
import {TermsAndConditions} from "./modules/portal/terms-and-conditions";
import {InformationClause} from "./modules/portal/information-clause";
import {ConsentsAndDeclarations} from "./modules/portal/consents-and-declarations";
import {DeviceList} from "./modules/services/devices/DeviceList";
import {DeviceDetailsCard} from "./modules/services/devices/DeviceDetailsCard";
import {ServiceOrderRequestDetails} from "./modules/services/service-order-requests/ServiceOrderRequestDetails";
import {ResetPassword} from "./modules/account/reset-password";

export class PrivateOutlet extends Component {
  public render(): JSX.Element {
    const isAuthenticated = AuthenticationService.isAuthenticated();
    if (isAuthenticated) {
      return (
          <>
            <Outlet />
          </>
      );
    }
    return <Navigate to="/account/login" replace />;
  }
}

function App() {
  return(
      <div className="App">
        <Routes>
            <Route path="/account/login" element={<LoginForm />}/>
            <Route path="/account/activate" element={<ActivateAccount />} />
            <Route path="/account/registration-confirmation" element={<RegistrationConfirmation />} />
            <Route path="/account/forgot-password" element={<ForgotPassword />} />
            <Route path="/account/reset-password" element={<ResetPassword />} />

            <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
            <Route path="/information-clause" element={<InformationClause/>} />
            <Route element={<PrivateOutlet/>}>
              <Route path="/" element={<PortalLayout />} >
              <Route path="/" element={<Dashboard />} />
              <Route path="/consents-and-declarations" element={<ConsentsAndDeclarations />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/my-company" element={<MyCompany />} />

              <Route path="/account/change-password" element={<ChangePassword />} />

              <Route path="/catalog/products" element={<ProductsList />} />

              <Route path="/documents/quotes" element={<QuotesList />} />
              <Route path="/documents/orders" element={<OrderList />} />
              <Route path="/documents/invoices" element={<InvoiceList />} />
              <Route path="/documents/credit-memos" element={<CreditMemoList />}/>

              <Route path="/quotes" element={<QuoteRequestList />} />
              <Route path="/quotes/quote-requests" element={<QuoteRequestList />} />
              <Route path="/quotes/quote-requests/:slug" element={<QuoteRequestDetails />} />

              <Route path="/orders/my-orders" element={<MyOrdersList />} />
              <Route path="/orders/my-orders/:slug" element={<OrderDetails />} />

              <Route path="/services" element={<ServiceOrderList/>} />
              <Route path="/services/devices" element={<DeviceList />} />
              <Route path="/services/devices/:slug" element={<DeviceDetailsCard />} />
              <Route path="/services/substitute-devices" element={<SubstituteDeviceList />} />
              <Route path="/services/service-order-requests" element={<ServiceOrderRequestList/>} />
              <Route path="/services/service-order-requests/:slug" element={<ServiceOrderRequestDetails/>} />
              <Route path="/services/service-orders" element={<ServiceOrderList/>} />
              <Route path="/services/service-orders/:slug" element={<ServiceOrderDetails/>} />
              <Route path="/services/service-invoices" element={<ServiceInvoiceList/>} />
            </Route>
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Route>
      </Routes>
      </div>
  )
}

export default App;
