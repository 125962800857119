import React, {FormEvent, useEffect, useState} from "react";
import Container from "@mui/material/Container";
import {PortalCard} from "../../../shared/portal-card";
import {useTranslation} from "react-i18next";
import {PagedResponse} from "../../../shared/paged-response";
import {HttpClient} from "../../../shared/http-client";
import Grid2 from "@mui/material/Unstable_Grid2";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {FormatDate} from "../../../Utils/utils";
import {StatusChip} from "../../../shared/status-chip";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, InputLabel, Select, SelectChangeEvent,
    TableFooter,
    TablePagination
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import {useNavigate, useSearchParams} from "react-router-dom";
import {UserInfo} from "../../../login/UserInfo";
import {AuthenticationService} from "../../../shared/authentication-service";
import MenuItem from "@mui/material/MenuItem";

interface QuoteRequest {
    id: string;
    slug: string;
    status: string;
    statusUpdatedAt: Date;
    number: string;
    referenceNumber: string;
    name: string;
    quoteRequestDate: Date;
    requestDeliveryDate: Date;
}

interface CreateNewQuoteRequest {
    referenceNumber: string;
    name: string;
    requestDeliveryDate: Date | null;
    buyerRemarks: string;
    contactPersonName: string;
    contactPersonEmail: string;
    contactPersonPhoneNumber: string;
    contactInformation: string;
    items: QuoteRequestItem[];
}

interface QuoteRequestItem {
    type: string;
    productId: string;
    quantity: number;
    unitOfMeasureCode: string;
}

const quoteRequestStatus = [
    'NEW',
    "RECEIVED",
    'PENDING APPROVAL',
    'APPROVED',
    'CANCELLED',
    'REJECTED',
    'FINISHED'
]

export function QuoteRequestList() {
    const [response, setResponse] = useState<PagedResponse<Array<QuoteRequest>>>();
    const [isLoading, setIsLoading] = useState(false);
    const [requestStatusFilter, setRequestStatusFilter] = useState<string[]>([]);
    const [orderBy, setOrderBy] = useState('request_date_desc');
    const [openNewQuoteRequestDialog, setOpenNewQuoteRequestDialog] = useState(false);
    const [openSelectCatalogItemDialog, setOpenSelectCatalogItemDialog] = useState(false);
    const [productNo, setProductNo] = useState('');
    const [productId, setProductId] = useState('');
    const [productNoDisabled, setProductNoDisabled] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [userFullName, setUserFullName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchParams] = useSearchParams();
    const [t] = useTranslation()
    const rowsPerPageOptions = [20, 40, 60];
    const initialPageNumber = 0;
    const quotesNs = 'quotes';

    let navigate = useNavigate();

    function ShowDetails(slug: string) {
        navigate(`/quotes/quote-requests/${slug}`,);
    }

    const handleCloseSelectCatalogItemDialog = () => {
        setOpenSelectCatalogItemDialog(false);
    }

    const handleOpenNewRequestDialog = () => {
        setOpenNewQuoteRequestDialog(true);
    }

    const handleCloseNewRequestDialog = () => {
        setOpenNewQuoteRequestDialog(false);
    }
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
        getQuoteRequests(newPage, rowsPerPage, orderBy, '');
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(initialPageNumber);
        getQuoteRequests(pageNumber, newRowsPerPage, orderBy, '');
    };

    function handleSubmitNewQuoteRequest(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        let qty = data.get('quantity') as number | null;
        if(!qty) {
            qty = 0;
        }

        const request: CreateNewQuoteRequest = {
            referenceNumber: data.get('referenceNumber') as string,
            name: data.get('name') as string,
            requestDeliveryDate : data.get('requestDeliveryDate') as Date | null,
            buyerRemarks : data.get('remarks') as string,
            contactPersonName: data.get('contactPersonName') as string,
            contactPersonPhoneNumber: data.get('contactPersonPhoneNumber') as string,
            contactPersonEmail: data.get('contactPersonEmail') as string,
            contactInformation: data.get('contactInformation') as string,
            items: [
                {
                    type: 'PRODUCT',
                    productId: productId,
                    quantity: qty,
                    unitOfMeasureCode: 'PCE'
                }
            ]
        };

        setIsLoading(true);

        HttpClient.post('quotes/quote-requests', JSON.stringify(request))
            .then(_ => {
                setOpenNewQuoteRequestDialog(false);
                getQuoteRequests(pageNumber, rowsPerPage, orderBy, '');
            })
            .catch(_ => console.debug(_))
            .finally(() => setIsLoading(false))
    }

    function getQuoteRequests(page: number, pageSize: number, status: string | null, order: string) {
        setIsLoading(true);

        page += 1;

        let requestUrl = `quotes/quote-requests/my?pageNumber=${page}&pageSize=${pageSize}`

        if(status) requestUrl += `&status=${status}`

        requestUrl += `&orderBy=${order}`;

        setIsLoading(true);

        HttpClient.get<PagedResponse<Array<QuoteRequest>>>(requestUrl)
            .then(_ => {
                setResponse(_);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChangeStatusFilter = (event: SelectChangeEvent<typeof requestStatusFilter>) => {
        const {
            target: { value },
        } = event;
        setRequestStatusFilter(
            typeof value === 'string' ? value.split(',') : value
        );
        getQuoteRequests(pageNumber, rowsPerPage, (value as Array<string>).join(','), orderBy)
    };

    const handleChangeOrderBy = (event: SelectChangeEvent) => {
        setOrderBy(event.target.value as string);
        getQuoteRequests(pageNumber, rowsPerPage, requestStatusFilter.join(','), event.target.value as string);
    };

    useEffect(() => {
        const userInfo = AuthenticationService.getUserInfo();
        setUserInfo(userInfo);

        setUserFullName(userInfo?.fullName!);
        setUserEmail(userInfo?.email!)
        const prodNo = searchParams.get('productNo');
        const prodId = searchParams.get('productId');
        const createNewQuoteRequest = searchParams.get('createNewQuoteRequest');

        if(prodNo) {
            setProductNo(prodNo);
            setProductNoDisabled(true);
        }

        if(prodId){
            setProductId(prodId)
        }

        if(createNewQuoteRequest && createNewQuoteRequest === "1")
            setOpenNewQuoteRequestDialog(true);

        getQuoteRequests(pageNumber, rowsPerPage, requestStatusFilter.join(','), orderBy);
    }, [])


    return(
        <Container>
            {/*<Grid container justifyContent="flex-end">*/}
            {/*    <Box sx={{mb: 2}}>*/}
            {/*        <Button*/}
            {/*            variant="contained"*/}
            {/*            color="success"*/}
            {/*            size="small"*/}
            {/*            startIcon ={<AddIcon/>}*/}
            {/*            onClick={handleOpenNewRequestDialog}*/}
            {/*            disabled={isLoading}*/}
            {/*        >*/}
            {/*            {t("button_createNewQuoteRequest", { ns: quotesNs })}*/}
            {/*        </Button>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}

            <PortalCard
                title={t("title_quoteRequests", { ns: quotesNs}).toUpperCase()}
                children={
                <Box>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={6}>
                        </Grid2>

                        <Grid2 xs={12} sm={6}>
                            <Grid2 container>
                                <Grid2 xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-filter-select-label" size='small'>
                                            Status
                                        </InputLabel>
                                        <Select
                                            labelId="status-filter-select-label"
                                            id="status-filter-select"
                                            multiple
                                            value={requestStatusFilter}
                                            label="Status"
                                            variant="outlined"
                                            onChange={handleChangeStatusFilter}
                                            size='small'
                                        >
                                            {quoteRequestStatus.map((status) => (
                                                <MenuItem
                                                    key={status}
                                                    value={status}
                                                >
                                                    <StatusChip props={{status : status!}}/>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid2>
                                <Grid2 xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="orderBy-select-label" size='small'>
                                            Sortowanie
                                        </InputLabel>
                                        <Select
                                            labelId="orderBy-select-label"
                                            id="orderBy-select"
                                            value={orderBy}
                                            label="Sortowanie"
                                            variant="outlined"
                                            onChange={handleChangeOrderBy}
                                            size='small'
                                        >
                                            <MenuItem value={'request_date_desc'}>{t("request_date_desc", { ns: quotesNs })}</MenuItem>
                                            <MenuItem value={'request_date_asc'}>{t("request_date_asc", { ns: quotesNs })}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid2>
                            </Grid2>
                        </Grid2>

                        <Grid2 xs={12}>
                            <Grid2 xs={12}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: 900}}>{t("number", { ns: quotesNs })}</TableCell>
                                                <TableCell sx={{fontWeight: 900}}>{t("referenceNumber", { ns: quotesNs })}</TableCell>
                                                <TableCell sx={{fontWeight: 900}}>{t("quoteRequestDate", { ns: quotesNs })}</TableCell>
                                                <TableCell sx={{fontWeight: 900}}>{t("status", { ns: quotesNs })}</TableCell>
                                                <TableCell sx={{fontWeight: 900}}>{t("name", { ns: quotesNs })}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {response?.data.map((item) =>
                                                <TableRow
                                                    onClick={() => ShowDetails(item.slug)}
                                                    key={item.id}
                                                    hover={true}
                                                >
                                                    <TableCell>{item.number}</TableCell>
                                                    <TableCell>{item.referenceNumber}</TableCell>
                                                    <TableCell>{FormatDate(item.quoteRequestDate)}</TableCell>
                                                    <TableCell><StatusChip props={{status : item?.status!}}/></TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    count={response?.meta.totalRecords || 0}
                                                    rowsPerPage={rowsPerPage}
                                                    page={pageNumber}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                    labelRowsPerPage={<span>Rows:</span>}
                                                    labelDisplayedRows={({ page }) => {
                                                        return `Page: ${page}`;
                                                    }}
                                                    backIconButtonProps={{
                                                        color: "secondary"
                                                    }}
                                                    nextIconButtonProps={{ color: "secondary" }}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "page number"
                                                        }
                                                    }}
                                                    showFirstButton={true}
                                                    showLastButton={true}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Box>
                }
                />

            <Dialog
                open={openNewQuoteRequestDialog}
                onClose={handleCloseNewRequestDialog}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseNewRequestDialog}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    component="form"
                    onSubmit={handleSubmitNewQuoteRequest}
                >
                    <DialogTitle>{t("title_newQuoteRequest", { ns: quotesNs })}</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            id="referenceNumber"
                            name="referenceNumber"
                            label={t("referenceNumber", { ns: quotesNs })}
                            type="text"
                            variant="outlined"
                            inputProps={{ spellCheck: 'false' }}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            name="name"
                            label={t("name", { ns: quotesNs })}
                            type="text"
                            variant="outlined"
                            inputProps={{ spellCheck: 'false' }}
                            required
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="requestedDeliveryDate"
                            name="requestedDeliveryDate"
                            label={t("requestedDeliveryDate", { ns: quotesNs })}
                            type="date"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="remarks"
                            name="remarks"
                            label={t("remarks", { ns: quotesNs })}
                            type="text"
                            variant="outlined"
                            multiline
                            minRows="2"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="contactPersonName"
                            name="contactPersonName"
                            defaultValue={userFullName}
                            label={t("contactPersonName", { ns: quotesNs })}
                            type="text"
                            variant="outlined"
                            required
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="contactPersonEmail"
                            name="contactPersonEmail"
                            defaultValue={userEmail}
                            label={t("contactPersonEmail", { ns: quotesNs })}
                            type="email"
                            variant="outlined"
                            required
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="contactPersonPhoneNumber"
                            name="contactPersonPhoneNumber"
                            label={t("contactPersonPhoneNumber", { ns: quotesNs })}
                            type="number"
                            variant="outlined"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="contactInformation"
                            name="contactInformation"
                            label={t("contactInformation", { ns: quotesNs })}
                            type="text"
                            variant="outlined"
                            multiline
                            minRows="2"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="productNo"
                            name="productNo"
                            label={t("productNo", { ns: quotesNs })}
                            value={productNo}
                            type="text"
                            variant="outlined"
                            disabled={productNoDisabled}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="quantity"
                            name="quantity"
                            label={t("quantity", { ns: quotesNs })}
                            type="number"
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained"
                                color="error"
                                disabled={isLoading}
                                onClick={handleCloseNewRequestDialog}>
                            {t("button_cancel", { ns: quotesNs })}
                        </Button>
                        <Button variant="contained"
                                color="success"
                                type="submit"
                                disabled={isLoading}
                        >
                            {t("button_create", { ns: quotesNs })}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}