import { useState } from "react";

export default function useServiceOrderRequestDialog() {
    const [serviceOrderRequestDialogIsOpen, setServiceOrderRequestDialogIsOpen] = useState(false);
    const [isLoading,setLoading] = useState(false)

    const showServiceOrderRequestDialog = () => {
        setServiceOrderRequestDialogIsOpen(true)
    };

    const hideServiceOrderRequestDialog = () => {
        setServiceOrderRequestDialogIsOpen(false)
    }

    const setIsLoading = () => {
        setLoading(true);
    }

    const setIsLoaded = () => {
        setLoading(false);
    }

    return {
        serviceOrderRequestDialogIsOpen,
        isLoading,
        showServiceOrderRequestDialog,
        hideServiceOrderRequestDialog,
        setIsLoading,
        setIsLoaded
    };
}