import React from "react";
import { useTranslation } from "react-i18next";

export function PortalLanguageSwitcher() {
    const { i18n } = useTranslation();

    function handleLangChange(e: React.FormEvent)  {
        const target = e.target as HTMLSelectElement;
        const lang: string = target.value;
        i18n.changeLanguage(lang)
            .then();
    }

    return (
        <select onChange={e => handleLangChange(e)} value={i18n.language}>
            <option value="pl">Polski</option>
            <option value="en">English</option>
        </select>
    );
}
