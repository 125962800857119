import {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {HttpClient} from "../../../shared/http-client";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {FormatDate} from "../../../Utils/utils";
import {StatusChip} from "../../../shared/status-chip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
    Alert,
    AlertColor, Checkbox,
    Dialog,
    DialogActions,
    DialogTitle, Snackbar
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";

interface ServiceOrderRequest {
    id: string;
    slug: string;
    status: string;
    referenceNumber: string;
    requestDate: Date;
    description: string;
    serviceOrderId: string;
    serviceOrderSlug: string;
    serviceOrderNumber: string;
    contactPersonName: string;
    contactPersonPhoneNumber: string;
    contactPersonEmail: string;
    contactInformation: string;
    formField1 : string;
    formField2 : string;
    formField3 : string;
    formField4 : string;
    formField5 : string;
    formField6 : string;
    formField7 : string;
    formField8 : string;
    formField9 : string;
    formField10 : string;
    formField11 : string;
    formField12 : string;
    formField13 : string;
    formField14 : string;
    formField15 : string;
    formField16 : string;
    formField17 : string;
    formField18 : string;
    formField19 : string;
    formField20 : string;
    formField21 : string;
    formField22 : string;
    formField23 : string;
    formField24 : string;
    formField25 : string;
    createdAt: Date;
    updatedAt: Date;
}

export function ServiceOrderRequestDetails() {
    const [serviceOrderRequestDetails, setServiceOrderRequestDetails] = useState<ServiceOrderRequest>();
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setSnackbarVisible] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("info")
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState<string>('')
    const [openCancelServiceRequestDialog, setOpenCancelServiceRequestDialog] = useState(false);
    const [substituteDeviceNeeded, setSubstituteDeviceNeeded] = useState(false);
    const [pickUpMyDevice, setPickUpMyDevice] = useState(false);
    const [t] = useTranslation();
    const { slug } = useParams();
    const servicesNs: string = 'services';

    function handleOpenCancelServiceRequestDialog() {
        setOpenCancelServiceRequestDialog(true);
    }

    function handleCloseCancelServiceRequestDialog() {
        setOpenCancelServiceRequestDialog(false);
    }

    function handleCancelCancelServiceRequestDialog() {
        setOpenCancelServiceRequestDialog(false);
    }

    function handleSubmitCancelServiceRequestDialog(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        setIsLoading(true)

        HttpClient.post(`services/service-order-requests/${serviceOrderRequestDetails?.id}/cancel`, null)
            .then(() => loadServiceOrderRequest())
            .catch(err => {
                setSnackbarErrorMessage(err.response.data.Errors[0])
                setSnackbarSeverity("error")
                setSnackbarVisible(true)
            })
            .finally(() => {
                setIsLoading(false);
                setOpenCancelServiceRequestDialog(false);
            })
    }

    function loadServiceOrderRequest() {
        setIsLoading(true);
        HttpClient.get<ServiceOrderRequest>(`services/service-order-requests/${slug}`)
            .then(response => {
                setServiceOrderRequestDetails(response);
                setSubstituteDeviceNeeded(response.formField6 === "true");
                setPickUpMyDevice(response.formField7 === "true");
            })
            .catch(err => {console.debug(err)})
            .finally(() => setIsLoading(false))
    }

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarVisible(false);
    };

    useEffect(() => {
        loadServiceOrderRequest()
    }, []);

    return(
        <Container>
            <Grid container justifyContent="flex-end">
                <Box sx={{mb: 2}}>
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        startIcon={<CancelIcon/>}
                        disabled={serviceOrderRequestDetails?.status.toUpperCase() !== "NEW"}
                        onClick={handleOpenCancelServiceRequestDialog}
                    >
                        {t("button_cancel")}
                    </Button>
                </Box>
            </Grid>
            <Grid>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Grid container>
                        <Grid container item xs={12} direction="column" >
                            <Typography variant="h5" gutterBottom>
                                <strong>{t("serviceOrderRequestNumber", { ns: servicesNs })}: {serviceOrderRequestDetails?.referenceNumber}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} >
                        <Grid container item xs={7} direction="column" >
                            <Typography>
                                <strong>{t("requestDate", { ns: servicesNs })}: </strong> {FormatDate(serviceOrderRequestDetails?.requestDate)}
                                <br/>
                                <strong>{t("status", { ns: servicesNs })}: </strong> <StatusChip props={{status : serviceOrderRequestDetails?.status!}}/>
                                <br/>
                                <strong>{t("serviceOrderNumber", { ns: servicesNs })}: </strong> {serviceOrderRequestDetails?.serviceOrderNumber ? serviceOrderRequestDetails?.serviceOrderNumber  : 'Brak'}
                                <br/>
                                <strong>{t("serviceItemType", { ns: servicesNs })}: </strong>{serviceOrderRequestDetails?.formField10}
                                <br/>
                                <strong>{t("serialNo", { ns: servicesNs })}: </strong>{serviceOrderRequestDetails?.formField11}
                                <br/>
                                <strong>{t("productionYear", { ns: servicesNs })}: </strong>{serviceOrderRequestDetails?.formField12}
                                <br/>
                                <strong>{t("oilManufacturer", { ns: servicesNs })}: </strong>{serviceOrderRequestDetails?.formField13}
                                <br/>
                                <strong>{t("oil", { ns: servicesNs })}: </strong>{serviceOrderRequestDetails?.formField14}
                                <br/>
                                <strong>{t("usageApplication", { ns: servicesNs })}: </strong>{serviceOrderRequestDetails?.formField15}
                                <br/>
                                <strong>{t("workingEnvironment", { ns: servicesNs })}: </strong>{serviceOrderRequestDetails?.formField16}
                                <br/>
                                <strong>{t("ambientTemperature", { ns: servicesNs })}: </strong>{serviceOrderRequestDetails?.formField17}
                                <br/>
                                <strong>{t("additionalEquipment", { ns: servicesNs })}: </strong>{serviceOrderRequestDetails?.formField18}
                                <br/>
                                <strong>{t("form_substituteDeviceNeeded", { ns: servicesNs })}: </strong>
                                <Checkbox checked={substituteDeviceNeeded} />
                                <br/>
                                <strong>{t("form_pickUpMyDevice", { ns: servicesNs })}: </strong>
                                <Checkbox checked={pickUpMyDevice}/>
                            </Typography>
                        </Grid>

                        <Grid container item xs={5} direction="column" >
                            <Typography>
                                <strong>{t("contactPerson", { ns: servicesNs })}:</strong>
                                <br/>
                                {serviceOrderRequestDetails?.contactPersonName}
                                <br/>
                                {serviceOrderRequestDetails?.contactPersonEmail}
                            </Typography>
                            {serviceOrderRequestDetails?.contactPersonPhoneNumber && <Typography>
                                {t("phoneNo", { ns: servicesNs })}: {serviceOrderRequestDetails?.contactPersonPhoneNumber}
                            </Typography>}
                            <br/>
                            <Typography>
                                <strong>{t("additionalInformation", { ns: servicesNs })}:</strong>
                            </Typography>
                            <Typography>
                                {serviceOrderRequestDetails?.contactInformation}
                            </Typography>
                           <br/>
                            <Typography>
                                <strong>{t("anotherDeliveryAddress", { ns: servicesNs })}:</strong>
                            </Typography>
                            <Typography>
                                {serviceOrderRequestDetails?.formField1}
                            </Typography>
                            <Typography>
                                {serviceOrderRequestDetails?.formField2}
                            </Typography>
                            <Typography>
                                {serviceOrderRequestDetails?.formField3}, {serviceOrderRequestDetails?.formField4}
                            </Typography>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid>
                        <Typography>
                            <strong>{t("faultDescription", { ns: servicesNs })}:</strong>
                        </Typography>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {serviceOrderRequestDetails?.formField20}
                        </Typography>
                    </Grid>
                    <br/>
                    <Grid>
                        <Typography>
                            <strong>{t("remarks", { ns: servicesNs })}:</strong>
                        </Typography>
                        <Typography>
                            {serviceOrderRequestDetails?.formField21}
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>

            <Dialog
                open={openCancelServiceRequestDialog}
                onClose={handleCloseCancelServiceRequestDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box
                    component="form"
                    onSubmit={handleSubmitCancelServiceRequestDialog}
                >
                    <DialogTitle id="alert-dialog-title">
                        {t("message_doYouWantToCancelRequest", { ns: servicesNs })} '{serviceOrderRequestDetails?.referenceNumber}' ?
                    </DialogTitle>
                    <DialogActions>
                        <Button variant="contained"
                                color="error"
                                disabled={isLoading}
                                onClick={handleCancelCancelServiceRequestDialog}>
                            {t("button_cancel")}
                        </Button>
                        <Button variant="contained"
                                color="success"
                                type="submit"
                                disabled={isLoading}>
                            {t("button_confirm")}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarErrorMessage}
                </Alert>
            </Snackbar>

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}