import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {HttpClient} from "../../../shared/http-client";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import {StatusChip} from "../../../shared/status-chip";
import Grid2 from "@mui/material/Unstable_Grid2";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";
import {FormatDate} from "../../../Utils/utils";
import {DeviceDetails} from "../interfaces/DeviceDetails";
import {DeviceNotes, DeviceNotesProps} from "./DeviceNotes";
import {DeviceUsage} from "./DeviceUsage";

export function DeviceDetailsCard() {
    const { slug } = useParams();
    const {t} = useTranslation()
    const [device, setDeviceDetails] = useState<DeviceDetails  | null>( null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deviceNotesProps, setDeviceNotesProps] = useState<DeviceNotesProps>({deviceId: ''});
    const [deviceUsageProps, setDeviceUsageProps] = useState<DeviceNotesProps>({deviceId: ''});
    const serviceNs: string = 'services';

    useEffect(() => {
        load()
    }, []);

    function load() {
        setIsLoading(true);
        HttpClient.get<DeviceDetails>(`services/devices/?slug=${slug}`)
            .then(_ => {
                setDeviceDetails(_)
                setDeviceNotesProps({deviceId: _.id})
                setDeviceUsageProps({deviceId: _.id})
            })
            .catch(_ => { console.debug(_)})
            .finally(() => {
                setIsLoading(false);
            })
    }

    return(
        <Container>
            <Grid2 container spacing={2}>
                <Paper>
                    <Grid2 container xs={12} padding={2}>
                        <Grid2 xs={12}>
                            <Typography variant="h5">
                                <strong>{t("serviceItem", { ns: serviceNs })}: {device?.no}</strong>
                            </Typography>
                        </Grid2>
                        <Grid2 container xs={12}>
                            <Grid2 xs={12} sm={6}>
                                <Typography>
                                    <strong>{t("no", { ns: serviceNs })}:</strong> {device?.no}
                                </Typography>
                                <Typography>
                                    <strong>{t("serialNo", { ns: serviceNs })}:</strong> {device?.serialNo}
                                </Typography>
                                <Typography>
                                    <strong>{t("status", { ns: serviceNs })}:</strong> <StatusChip props={{status : device?.status!}}></StatusChip>
                                </Typography>
                                <Typography>
                                    <strong>{t("name", { ns: serviceNs })}:</strong>
                                </Typography>
                                <Typography>
                                    {device?.name}
                                </Typography>
                                <br/>
                                <Typography>
                                    <strong>{t("location", { ns: serviceNs })}:</strong>
                                </Typography>
                                <Typography variant="body2">
                                    <Typography>
                                        {device?.locationName}{device?.locationName2} {<br/>}
                                    </Typography>
                                    <Typography>
                                        {device?.locationAddress}{device?.locationAddress2}{<br/>}
                                    </Typography>
                                    <Typography>
                                        {device?.locationPostCode} {device?.locationCity}
                                    </Typography>
                                    <Typography>
                                        {device?.locationCountryCode}
                                    </Typography>
                                </Typography>
                            </Grid2>
                            <Grid2 xs={12} sm={6}>
                                <Typography>
                                    <strong>{t("productionYear", { ns: serviceNs })}:</strong> {device?.productionYear}
                                </Typography>
                                <Typography>
                                    <strong>{t("lastServiceDate", { ns: serviceNs})}:</strong> {FormatDate(device?.lastServiceDate)}
                                </Typography>
                            </Grid2>
                        </Grid2>

                        <Grid2 xs={12} sx={{ paddingTop: 2, paddingBottom: 0}}>
                            <Grid2 xs={12}>
                                <Typography>
                                    <strong>{t("relatedDocuments", {ns: "services"})}:</strong>
                                </Typography>
                            </Grid2>
                            <Grid2 container xs={12} sx={{ paddingTop: 0}}>
                                { deviceUsageProps.deviceId && <DeviceUsage props={deviceUsageProps} />}
                            </Grid2>
                        </Grid2>

                        <Grid2 container sx={{ paddingTop: 0, paddingBottom: 0}} xs={12}>
                            <Grid2 xs={12}>
                                <Typography>
                                    <strong>{t("notes", {ns: "services"})}:</strong>
                                </Typography>
                            </Grid2>
                            <Grid2 container xs={12} sx={{ paddingTop: 0}}>
                                { deviceNotesProps.deviceId && <DeviceNotes props={deviceNotesProps} />}
                            </Grid2>
                        </Grid2>

                    </Grid2>
                </Paper>
            </Grid2>

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}