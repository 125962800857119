import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { FormatDate, FormatTotal} from "../../../Utils/utils";
import {
    FormControl,
    Box,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent,
    TableFooter,
    TablePagination,
    TextField,
    IconButton,
    Button
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Container from "@mui/material/Container";
import React, { useEffect, useState} from "react";
import {PagedResponse} from "../../../shared/paged-response";
import {useTranslation} from "react-i18next";
import {HttpClient} from "../../../shared/http-client";
import {PortalCard} from "../../../shared/portal-card";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Grid2 from "@mui/material/Unstable_Grid2";
import {StatusChip} from "../../../shared/status-chip";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";

const statuses = [
    'paid',
    'partial paid',
    'overdue'
]

interface ServiceInvoice {
    id: string;
    slug: string;
    number: string;
    referenceNumber: string;
    status: string;
    orderNumber: string;
    description: string;
    documentDate: Date;
    postingDate: Date;
    dueDate: Date;
    currencyCode: string;
    amount: number;
    vatAmount: number;
    amountInclVat: number;
    invoicePdfUrl: string;
}

export function ServiceInvoiceList() {
    const [response, setResponse] = useState<PagedResponse<Array<ServiceInvoice>>>();
    const [invoiceStatusFilter, setInvoiceStatusFilter] = useState<string[]>([]);
    const [orderBy, setOrderBy] = useState('document_date_desc');
    const [documentNoFilter, setDocumentNoFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const rowsPerPageOptions = [20, 40, 60];
    const initialPageNumber = 0;
    const servicesNs = 'services';

    useEffect(() => {
        load(pageNumber, rowsPerPage, orderBy, documentNoFilter, invoiceStatusFilter.join(','));
    }, [])

    const handleChangeOrderBy = (event: SelectChangeEvent) => {
        setOrderBy(event.target.value as string);
        load(pageNumber, rowsPerPage, event.target.value as string, documentNoFilter, invoiceStatusFilter.join(','));
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
        load(newPage, rowsPerPage, orderBy, documentNoFilter, invoiceStatusFilter.join(','));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(initialPageNumber);
        load(pageNumber, newRowsPerPage, orderBy, documentNoFilter, invoiceStatusFilter.join(','));
    };

    function load(page: number,
                  pageSize: number,
                  order: string,
                  documentNo: string | null,
                  status: string | null){

        setIsLoading(true);

        page += 1;
        let requestUrl = `services/service-invoices/my-company?pageNumber=${page}&pageSize=${pageSize}`;

        if (documentNo && documentNo.length > 0) requestUrl += `&documentNo=${documentNo}`

        if (status) requestUrl += `&status=${status}`

        requestUrl += `&orderBy=${order}`

        HttpClient.get<PagedResponse<Array<ServiceInvoice>>>(requestUrl)
            .then(_ => {
                setResponse(_);
                setIsLoading(false);
            })
            .catch(_ => {
                setIsLoading(false);
            })
    }

    function handleDocumentNoFilterChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setDocumentNoFilter(event.target.value as string)
    }

    const handleChangeStatusFilter = (event: SelectChangeEvent<typeof  invoiceStatusFilter>) => {
        const {
            target: { value },
        } = event;
        setInvoiceStatusFilter(
            typeof value === 'string' ? value.split(',') : value
        );
        load(pageNumber, rowsPerPage, orderBy, documentNoFilter, (value as Array<string>).join(','));
    };

    return(
        <Container>
            <PortalCard
                title={t("serviceInvoicesList", { ns: servicesNs }).toUpperCase()}
                children={
                <Box>
                    <Grid2 container spacing={2} padding={1}>
                        <Grid2 xs={12} sm={6}>
                            <FormControl>
                                <TextField
                                    id="search-bar"
                                    className="text"
                                    label={t("no", { ns: servicesNs })}
                                    variant="outlined"
                                    size="small"
                                    value={documentNoFilter}
                                    onChange={handleDocumentNoFilterChange}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => load(pageNumber, rowsPerPage, orderBy, documentNoFilter, invoiceStatusFilter.join(','))}>
                                                <SearchIcon />
                                            </IconButton>
                                        )
                                    }}
                                />
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel id="status-filter-select-label" size='small'>
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="status-filter-select-label"
                                    id="status-filter-select"
                                    multiple
                                    value={invoiceStatusFilter}
                                    label="Status"
                                    variant="outlined"
                                    onChange={handleChangeStatusFilter}
                                    size='small'
                                >
                                    {statuses.map((status) => (
                                        <MenuItem
                                            key={status}
                                            value={status}
                                        >
                                            <StatusChip props={{status : status!}}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={12} sm={3}>
                            <FormControl>
                                <InputLabel id="orderBy-select-label" size='small'>
                                    {t("orderBy", { ns: servicesNs })}
                                </InputLabel>
                                <Select
                                    labelId="orderBy-select-label"
                                    id="orderBy-select"
                                    value={orderBy}
                                    label={t("orderBy", { ns: servicesNs })}
                                    variant="outlined"
                                    onChange={handleChangeOrderBy}
                                    size='small'
                                >
                                    <MenuItem value={'document_date_desc'}>{t("document_date_desc", { ns: "services"})}</MenuItem>
                                    <MenuItem value={'document_date_asc'}>{t("document_date_asc", { ns: "services"})}</MenuItem>
                                    <MenuItem value={'due_date_desc'}>{t("due_date_desc", { ns: "services"})}</MenuItem>
                                    <MenuItem value={'due_date_asc'}>{t("due_date_asc", { ns: "services"})}</MenuItem>
                                    <MenuItem value={'number_desc'}>{t("number_desc", { ns: "services"})}</MenuItem>
                                    <MenuItem value={'number_asc'}>{t("number_asc", { ns: "services"})}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={12}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: 900}} align="left">{t("no", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900}} align="left">{t("serviceOrderNo", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900}} align="left">{t("orderNo", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900}} align="left">{t("documentDate", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900}} align="left">{t("dueDate", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900}} align="left">{t("status", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900}} align="right">{t("total", { ns: servicesNs })}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {response?.data.map((item) =>
                                            <TableRow
                                                key={item.id}
                                                hover={true}
                                            >
                                                <TableCell>{item.number}</TableCell>
                                                <TableCell>{item.orderNumber}</TableCell>
                                                <TableCell>{item.referenceNumber}</TableCell>
                                                <TableCell>{FormatDate(item.documentDate)}</TableCell>
                                                <TableCell>{FormatDate(item.dueDate)}</TableCell>
                                                <TableCell><StatusChip props={{status : item?.status!}}/></TableCell>
                                                <TableCell align="right">{FormatTotal(item.amountInclVat, item.currencyCode)}</TableCell>
                                                <TableCell>
                                                    <Button disabled={!item.invoicePdfUrl}
                                                            onClick={() => window.open(item.invoicePdfUrl)}
                                                            sx={{padding: 0}}
                                                    >
                                                        <PictureAsPdfIcon/>
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                count={response?.meta.totalRecords || 0}
                                                rowsPerPage={rowsPerPage}
                                                page={pageNumber}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                rowsPerPageOptions={rowsPerPageOptions}
                                                labelRowsPerPage={<span>Rows:</span>}
                                                labelDisplayedRows={({ page }) => {
                                                    return `Page: ${page}`;
                                                }}
                                                backIconButtonProps={{
                                                    color: "secondary"
                                                }}
                                                nextIconButtonProps={{ color: "secondary" }}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "page number"
                                                    }
                                                }}
                                                showFirstButton={true}
                                                showLastButton={true}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid2>
                    </Grid2>
                </Box>
                }
            />

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}