import Typography from "@mui/material/Typography";
import React  from "react";
import {useTranslation} from "react-i18next";
import {FormatDate, FormatTotal} from "../../Utils/utils";
import {CustomerDetails} from "./customer-details";
import Box from "@mui/material/Box";

export function TotalOutstanding({props}: { props: React.PropsWithChildren<CustomerDetails> }) {
    const {t} = useTranslation()

    return(
        <Box
            bgcolor="white"
            sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                height: 220,
                border: 1,
                borderColor: "#DDE2E9"
            }}
        >
            <Box bgcolor="primary.main">
                <Typography
                    component="h6"
                    variant="h6"
                    color="white"
                    sx={{p: 1}}
                >
                    {t("dashboard_balance").toUpperCase()}
                </Typography>
            </Box>
            <Box sx={{p: 1}}>
                <Typography component="p" variant="h5">
                    <strong>{FormatTotal(props?.totalOutstanding, props?.totalOutstandingCurrencyCode)}</strong>
                </Typography>
                <Typography color="text.secondary" sx={{ flex: 1, fontSize: 15 }}>
                    {t("odDate")} {FormatDate(props?.totalOutstandingUpdatedAt)}
                </Typography>
            </Box>
        </Box>
    )
}