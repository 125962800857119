import { UserInfo } from "../login/UserInfo";
import { AuthenticationResult } from "../login/authentication/authenticationResult";
import { LoginConstants } from "../login/authentication/login-constants";
import { TokenService } from '../shared/token-service';

export class AuthenticationService {

    public static authenticate(authenticationResult: AuthenticationResult): void {
        TokenService.setAccessToken(authenticationResult.token.accessToken)
        TokenService.setRefreshToken(authenticationResult.token.refreshToken);
    }

    public static setUserInfo(userInfo: UserInfo): void {
        console.log(userInfo);
        window.localStorage.setItem(LoginConstants.USER_INFO_KEY, JSON.stringify(userInfo));
    }

    public static getUserInfo(): UserInfo | null {
        const userInfo: UserInfo = JSON.parse(window.localStorage.getItem(LoginConstants.USER_INFO_KEY)!);
        return userInfo;
    }

    public static isAuthenticated(): boolean {
        return window.localStorage.getItem(LoginConstants.ACCESS_TOKEN_KEY) != null;
    }

    public static logout(reload: boolean): void {
        window.localStorage.removeItem(LoginConstants.ACCESS_TOKEN_KEY);
        window.localStorage.removeItem(LoginConstants.REFRESH_TOKEN_KEY);
        window.localStorage.removeItem(LoginConstants.USER_INFO_KEY);

        if(reload) {
            window.location.reload();
        }
    }

}