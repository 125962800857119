import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {FormEvent, useEffect, useState} from "react";
import {HttpClient} from "../../../shared/http-client";
import {useParams} from "react-router-dom";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import {StatusChip} from "../../../shared/status-chip";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    ListItem,
    MenuItem,
    Select, SelectChangeEvent,
    Snackbar,
    Stack, TableFooter
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import AttachmentIcon from '@mui/icons-material/Attachment';
import ListItemIcon from "@mui/material/ListItemIcon";
import {FormatAmount, FormatTotal} from "../../../Utils/utils";
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";
import ClearIcon from '@mui/icons-material/Clear';

interface ServiceOrderLine {
    lineNumber: number;
    lineIdentifier: string;
    serviceItemId: string;
    serviceItemNo: string;
    type: string;
    no: string;
    id: string;
    description: string;
    description2: string;
    quantity: number;
    unitOfMeasureCode: string;
    unitPrice: number;
    discount: number;
    amount: number;
    vatAmount: number;
    amountInclVat: number;
}

interface ServiceOrderItem {
    id: string;
    slug: string;
    no: string;
    serialNo: string;
    description: string;
    repairStatusId: string;
    repairStatusCode: string;
    repairStatusDescription: string;
}

interface ServiceOrderAttachment {
    name: string;
    description: string;
    url: string;
}

interface ServiceOrderDetails {
    id: string;
    slug: string;
    number: string;
    referenceNumber: string;
    serviceOrderRequestReferenceNumber: string;
    status: string;
    statusUpdatedAt: Date;
    serviceRemarks: string;
    workLocation: string;
    orderDateTime: string;
    startingDateTime: Date;
    endingDateTime: Date;
    expectedFinishingDate: Date;
    plannedEndDateText: string;
    customerName: string;
    customerName2: string;
    customerAddress: string;
    customerAddress2: string;
    customerPostCode: string;
    customerCity: string;
    customerCountryCode: string;
    billToName: string;
    billToName2: string;
    billToAddress: string;
    billToAddress2: string;
    billToPostCode: string;
    billToCity: string;
    billToCountryCode: string;
    billToVatRegistrationNo: string;
    shipToName: string;
    shipToName2: string;
    shipToAddress: string;
    shipToAddress2: string;
    shipToPostCode: string;
    shipToCity: string;
    shipToCountryCode: string;
    contactPersonName: string;
    contactPersonPhoneNumber: string;
    contactPersonEmail: string;
    contactInformation: string;
    responsiblePersonName: string;
    responsiblePersonPhoneNumber: string;
    responsiblePersonEmail: string;
    expertise: string;
    currencyCode: string;
    currencyFactor: number;
    scrappingFee: number;
    invDiscountAmount: number;
    amount: number;
    vatAmount: number;
    amountInclVat: number;
    amountInclVatLcy: number;
    paymentTerms: string;
    paymentRemarks: string;
    approvesBy: string;
    approvedAt: Date;
    rejectedReason: string;
    rejectedBy: string;
    rejectedAt: Date;
    userAttachmentFileId: string;
    userAttachmentFileName: string;
    userAttachmentFileUrl: string;
    items: ServiceOrderItem[];
    lines: ServiceOrderLine[];
    attachments: ServiceOrderAttachment[];
}

interface RejectServiceOrderRequest {
    reason: string;
}

interface ApproveServiceOrderRequest {
    referenceNumber: string;
    fileName: string;
    fileBase64: string;
    fileSize: number;
}



export function ServiceOrderDetails() {
    const [serviceOrderDetails, setResponse] = useState<ServiceOrderDetails | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [startingEndingDateTimeVisible, setStartingEndingDateTimeVisible] = useState(false);
    const [t,] = useTranslation()
    const [openApproveOrderDialog, setOpenApproveOrderDialog] = useState(false);
    const [openRejectOrderDialog, setOpenRejectOrderDialog] = useState(false);
    const [openSuccessSnackbar , setOpenSuccessSnackbar] = useState(false);
    const [openErrorSnackbar , setOpenErrorSnackbar] = useState(false);
    const [fileLoaded, setFileLoaded] = useState(false);

    const [rejectedReason, setRejectedReason] = useState("");
    const [scrappingFee, setScrappingFee] = useState(0);
    const { slug } = useParams();
    const servicesNs = 'services';

    let rejectedReasons = [
        `Proszę o odpłate złomowanie dostarczonego urządzenia. Koszt złomowania ${scrappingFee} EUR`,
        'Proszę odesłać niesprawne urządzenie na koszt zlecającego',
        'Zamawiam nowe urządzenie'
    ]

    function handleOpenApproveOrderDialog() {
        setOpenApproveOrderDialog(true);
    }

    function handleCloseApproveOrderDialog() {
        setOpenApproveOrderDialog(false);
    }

    function handleCancelApproveOrderDialog() {
        setOpenApproveOrderDialog(false);
    }

    function handleConfirmApproveOrderDialog(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        let fileName: string = '';
        let fileSize: number = 0;

        if (fileSelected) {
            fileName = fileSelected.name as string;
            fileSize = fileSelected.size as number;
        }

        const request: ApproveServiceOrderRequest = {
            referenceNumber: data.get('referenceNumber') as string,
            fileName: fileName as string,
            fileSize: fileSize as number,
            fileBase64: fileBase64 as string
        };

        setIsLoading(true);

        HttpClient.post(`services/service-orders/${serviceOrderDetails?.id}/approve`, JSON.stringify(request))
            .then(_ => {
                loadServiceOrderDetails();
                setIsLoading(false);
                setOpenApproveOrderDialog(false);
                setOpenSuccessSnackbar(true);
            })
            .catch( _ => {
                setOpenErrorSnackbar(true)
                setIsLoading(false);
            });
    }

    function handleOpenRejectOrderDialog() {
        setOpenRejectOrderDialog(true);
    }

    function handleCloseRejectOrderDialog() {
        setOpenRejectOrderDialog(false);
    }

    function handleCancelRejectOrderDialog() {
        setOpenRejectOrderDialog(false);
    }

    function handleConfirmRejectOrderDialog(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const request: RejectServiceOrderRequest = {
            reason: rejectedReason
        };

        HttpClient.post(`services/service-orders/${serviceOrderDetails?.id}/reject`, JSON.stringify(request))
            .then(_ => {
                loadServiceOrderDetails();
                setOpenRejectOrderDialog(false);
                setOpenSuccessSnackbar(true);
            })
            .catch( _ => {
                setOpenErrorSnackbar(true)
            });
    }

    function handleCloseSuccessSnackbar(event?: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessSnackbar(false);
    }

    function handleCloseErrorSnackbar(event?: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorSnackbar(false);
    }

    useEffect(() => {
        setIsLoading(true);
        loadServiceOrderDetails();
    }, [])

    function loadServiceOrderDetails() {
        HttpClient.get<ServiceOrderDetails>(`services/service-orders/${slug}`)
            .then(_ => {
                setResponse(_)
                console.clear()
                console.log(_.scrappingFee)
                if(_.scrappingFee) {
                    setScrappingFee(_.scrappingFee);
                }

                setIsLoading(false);
            })
            .finally(() => {
                if (serviceOrderDetails?.status.toUpperCase() === "NEW" ||
                    serviceOrderDetails?.status.toUpperCase() === "PENDING APPROVAL" ||
                    serviceOrderDetails?.status.toUpperCase() === "REJECTED") {
                    setStartingEndingDateTimeVisible(false);
                } else {
                    setStartingEndingDateTimeVisible(true);
                }
            })
            .catch(_ => {
            })
    }


    const [fileSelected, setFileSelected] = React.useState<File | null>()
    const [fileBase64, setFileBase64] = useState('');

    function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;

        if (!fileList) return;

        setFileSelected(fileList[0]);

        const file = fileList[0] as File;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend  = () => {
            if(reader) {
                if (typeof reader.result === "string") {
                    const fileBase64 = reader.result?.replace('data:', '').replace(/^.+,/, '') as string;
                    setFileBase64(fileBase64);
                    setFileLoaded(true);
                }
            }
        };
    };

    function uploadFile(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        if (fileSelected) {

        }
    }

    function deleteFile(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        setFileSelected(null);
        setFileBase64('');
        setFileLoaded(false);
    }

    function handleChangeRejectedReason(event: SelectChangeEvent) {
        setRejectedReason(event.target.value);
    }

    return(
        <Container>
            <Grid container justifyContent="flex-end">
                <Box sx={{mb: 2}}>
                    <Stack spacing={2} direction="row">
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            startIcon={<DoneIcon/>}
                            disabled={serviceOrderDetails?.status.toUpperCase() !== "PENDING APPROVAL"}
                            onClick={handleOpenApproveOrderDialog}
                        >
                            {t("button_approve")}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            size="small"
                            startIcon={<CancelIcon/>}
                            disabled={serviceOrderDetails?.status.toUpperCase() !== "PENDING APPROVAL"}
                            onClick={handleOpenRejectOrderDialog}
                        >
                            {t("button_reject")}
                        </Button>
                    </Stack>
                </Box>
            </Grid>
           <Grid>
               <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                   <Grid container>
                       <Grid container item xs={12} direction="column" >
                           <Typography variant="h5" gutterBottom>
                               <strong>{t("order", { ns: servicesNs })} {serviceOrderDetails?.number}</strong>
                           </Typography>
                       </Grid>
                   </Grid>

                   <Grid container spacing={2} >
                       <Grid container item xs={6} direction="column" >

                           <Typography>
                               <strong>{t("yourServiceRefNo", { ns: servicesNs })}:</strong> {serviceOrderDetails?.serviceOrderRequestReferenceNumber}
                           </Typography>
                           <Typography>
                               <strong>{t("orderNumber", { ns: servicesNs })}:</strong> {serviceOrderDetails?.referenceNumber}
                           </Typography>
                           <Typography>
                               <strong>{t("status", { ns: servicesNs })}:</strong> <StatusChip props={{status : serviceOrderDetails?.status!}}/>
                           </Typography>
                           <Typography>
                               <strong>{t("updateDateTime", { ns: servicesNs })}:</strong> {serviceOrderDetails?.statusUpdatedAt != null ? moment.utc(serviceOrderDetails?.statusUpdatedAt).local().format('YYYY-MM-DD HH:mm') : "N/A"}
                           </Typography>
                       </Grid>

                       <Grid container item xs={6} direction="column" >
                           <Typography>
                               <strong>{t("orderDate", { ns: servicesNs })}:</strong> {moment.utc(serviceOrderDetails?.orderDateTime).local().format('YYYY-MM-DD HH:mm')}
                           </Typography>
                           { startingEndingDateTimeVisible && <Typography>
                                   <strong>{t("startingDateTime", { ns: servicesNs })}:</strong> {moment.utc(serviceOrderDetails?.startingDateTime).local().format('YYYY-MM-DD HH:mm')}
                               </Typography> }
                           { startingEndingDateTimeVisible && <Typography>
                               <strong>{t("plannedEndDateText", { ns: servicesNs })}:</strong> {serviceOrderDetails?.plannedEndDateText}
                           </Typography> }
                           { startingEndingDateTimeVisible && <Typography>
                                   <strong>{t("endingDateTime", { ns: servicesNs })}:</strong> {serviceOrderDetails?.endingDateTime && moment.utc(serviceOrderDetails?.endingDateTime).local().format('YYYY-MM-DD HH:mm')}
                           </Typography> }
                       </Grid>

                       <Grid container item xs={6} direction="column" >
                           <Typography>
                               <strong>{t("billTo", { ns: servicesNs })}:</strong>
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.billToName}{serviceOrderDetails?.billToName2}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.billToAddress}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.billToAddress2}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.billToPostCode} {serviceOrderDetails?.billToCity}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.billToCountryCode}
                           </Typography>
                           <Typography>
                               {t("vatRegistrationNo", { ns: servicesNs })}: {serviceOrderDetails?.billToVatRegistrationNo}
                           </Typography>
                       </Grid>

                       <Grid container item xs={6} direction="column" >
                           <Typography>
                               <strong>{t("shipTo", { ns: servicesNs })}:</strong>
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.shipToName}{serviceOrderDetails?.shipToName2}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.shipToAddress}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.shipToAddress2}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.shipToPostCode} {serviceOrderDetails?.shipToCity}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.shipToCountryCode}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.contactPersonName}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.contactPersonPhoneNumber}
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.contactPersonEmail}
                           </Typography>
                       </Grid>

                       { serviceOrderDetails?.serviceRemarks && <Grid container item xs={12} direction="column" >
                           <Typography>
                               <strong>{t("remarks", { ns: servicesNs })}:</strong>
                           </Typography>
                           <Typography>
                               {serviceOrderDetails?.serviceRemarks}
                           </Typography>
                       </Grid>}

                       { serviceOrderDetails?.expertise && <Grid container item xs={12} direction="column" >
                           <Typography>
                               <strong>{t("expertise", { ns: servicesNs })}:</strong>
                           </Typography>
                           <Typography
                                    dangerouslySetInnerHTML={{
                                        __html:serviceOrderDetails?.expertise
                                    }}>
                           </Typography>
                       </Grid> }

                      {serviceOrderDetails?.rejectedReason &&  <Grid container item xs={12} direction="column">
                          <Typography>
                              <strong>{t("rejectedReason", { ns: servicesNs })}:</strong>
                          </Typography>
                          <Typography>
                              {serviceOrderDetails?.rejectedReason}
                          </Typography>
                      </Grid>}

                   </Grid>
                   <Grid mt={3}>
                       <Typography>
                           <strong>{t("device", { ns: servicesNs })}:</strong>
                       </Typography>
                       <Divider/>
                   </Grid>
                   <Grid>
                       <TableContainer>
                           <Table size="small">
                               <TableHead>
                                   <TableRow>
                                       <TableCell width="5%"><strong>{t("no", { ns: servicesNs })}</strong></TableCell>
                                       <TableCell width="15%"><strong>{t("serialNo", { ns: servicesNs })}</strong></TableCell>
                                       <TableCell width="80%"><strong>{t("description", { ns: servicesNs })}</strong></TableCell>
                                   </TableRow>
                               </TableHead>
                               <TableBody>
                                   {serviceOrderDetails?.items.map((item) =>
                                       <TableRow
                                           key={item?.id}
                                       >
                                           <TableCell>{item?.no}</TableCell>
                                           <TableCell>{item?.serialNo}</TableCell>
                                           <TableCell>{item?.description}</TableCell>
                                       </TableRow>
                                   )}
                               </TableBody>
                           </Table>
                       </TableContainer>
                   </Grid>
                   <Grid mt={3}>
                       <Typography>
                           <strong>{t("summaryOfUsedParts", { ns: servicesNs })}:</strong>
                       </Typography>
                       <Divider/>
                   </Grid>
                   <Grid>
                       <TableContainer>
                           <Table size="small">
                               <TableHead>
                                   <TableRow key="header">
                                       <TableCell width="5%"><strong>{t("no", { ns: servicesNs })}</strong></TableCell>
                                       <TableCell width="15%"><strong>{t("catalogNo", { ns: servicesNs })}</strong></TableCell>
                                       <TableCell width="43%"><strong>{t("description", { ns: servicesNs })}</strong></TableCell>
                                       <TableCell width="5%"><strong>{t("quantity", { ns: servicesNs })}</strong></TableCell>
                                       <TableCell width="10%" align="right"><strong>{t("unitPrice", { ns: servicesNs })}</strong></TableCell>
                                       <TableCell width="10%" align="right"><strong>{t("discount", { ns: servicesNs })} (%)</strong></TableCell>
                                       <TableCell width="12%" align="right"><strong>{t("amount", { ns: servicesNs })}</strong></TableCell>
                                   </TableRow>
                               </TableHead>
                               <TableBody>
                                   {serviceOrderDetails?.lines.map((item) =>
                                       <TableRow
                                           key={item?.id}
                                       >
                                           <TableCell>{item?.serviceItemNo}</TableCell>
                                           <TableCell>{item?.no}</TableCell>
                                           <TableCell>{item?.description}{item?.description2}</TableCell>
                                           <TableCell>{FormatAmount(item?.quantity)}</TableCell>
                                           <TableCell align="right">{FormatAmount(item?.unitPrice)}</TableCell>
                                           <TableCell align="right">{FormatAmount(item?.discount)}</TableCell>
                                           <TableCell align="right">{FormatAmount(item?.amount)}</TableCell>
                                       </TableRow>
                                   )}
                               </TableBody>
                               <TableFooter>
                                   { serviceOrderDetails?.invDiscountAmount! > 0 && <TableRow key="invoiceDiscount">
                                       <TableCell colSpan={5} />
                                       <TableCell>{t("invDiscount", { ns: servicesNs })}</TableCell>
                                       <TableCell align="right">{FormatTotal(serviceOrderDetails?.invDiscountAmount, serviceOrderDetails?.currencyCode)}</TableCell>
                                   </TableRow>}
                                   <TableRow key="subtotal">
                                       <TableCell colSpan={5} />
                                       <TableCell>{t("net", { ns: servicesNs })}</TableCell>
                                       <TableCell align="right">{FormatTotal(serviceOrderDetails?.amount, serviceOrderDetails?.currencyCode)}</TableCell>
                                   </TableRow>
                                   <TableRow key="tax">
                                       <TableCell colSpan={5} />
                                       <TableCell>{t("vat", { ns: servicesNs })}</TableCell>
                                       <TableCell align="right">{FormatTotal(serviceOrderDetails?.vatAmount, serviceOrderDetails?.currencyCode)}</TableCell>
                                   </TableRow>
                                   <TableRow key="total">
                                       <TableCell colSpan={5} />
                                       <TableCell><strong>{t("gross", { ns: servicesNs })}</strong></TableCell>
                                       <TableCell align="right"><strong>{FormatTotal(serviceOrderDetails?.amountInclVat, serviceOrderDetails?.currencyCode)}</strong></TableCell>
                                   </TableRow>
                               </TableFooter>
                           </Table>
                       </TableContainer>
                   </Grid>
                   <Grid mt={3}>
                       <Typography gutterBottom>{serviceOrderDetails?.paymentRemarks}</Typography>
                       { serviceOrderDetails?.currencyFactor && <Typography gutterBottom><strong>{t("currencyFactor", { ns: servicesNs })}:</strong> {serviceOrderDetails?.currencyFactor?.toFixed(4)}</Typography>}
                       { serviceOrderDetails?.currencyFactor && <Typography gutterBottom><strong>{t("grossAmountInLcy", { ns: servicesNs })}:</strong> {FormatAmount(serviceOrderDetails?.amountInclVatLcy)}</Typography>}
                       <Typography><strong>{t("paymentTerms", { ns: servicesNs })}:</strong> {serviceOrderDetails?.paymentTerms}</Typography>

                   </Grid>
                   <Grid mt={3}>
                       <Typography sx={{fontWeight: 600}} mt={2}>
                           Na przeprowadzone prace serwisowe udzielamy 6 miesięcznej gwarancji.
                       </Typography>
                   </Grid>
                   <Grid mt={3}>
                       <Typography gutterBottom>
                           <strong>{t("contact", { ns: servicesNs })}</strong>
                       </Typography>
                       <Divider/>
                       <Typography>Becker Polska Sp. z o.o.</Typography>
                       <Typography>Pianowo 46</Typography>
                       <Typography>64-000 Kościan</Typography>
                       <Typography>+48 655114172</Typography>
                       <Typography>serwis@becker-polska.com</Typography>
                       <Typography><strong>{t("contactPerson", { ns: servicesNs })}:</strong></Typography>
                       <Typography>{serviceOrderDetails?.responsiblePersonName}</Typography>
                       <Typography>{serviceOrderDetails?.responsiblePersonEmail}</Typography>
                   </Grid>
                   {serviceOrderDetails?.userAttachmentFileName && <Grid mt={3}>
                       <Typography gutterBottom>
                           <strong>{t("userAttachment", { ns: servicesNs }).toUpperCase()}: </strong> <a href={serviceOrderDetails?.userAttachmentFileUrl} target="_blank">{serviceOrderDetails?.userAttachmentFileName}</a>
                       </Typography>
                   </Grid>}
                   <Grid mt={3}>
                       <Typography gutterBottom>
                           <strong>{t("documentsAndPhotos", { ns: servicesNs })}</strong>:
                       </Typography>
                       <Divider/>

                       <List>
                           {serviceOrderDetails?.attachments.map((attachment) =>
                               <ListItem button component="a" href={attachment?.url} target="_blank">
                                   <ListItemIcon>
                                       <AttachmentIcon/>
                                   </ListItemIcon>
                                   <ListItemText primary={attachment?.name} secondary={attachment?.description} />
                               </ListItem>
                           )}
                       </List>
                   </Grid>
               </Paper>
           </Grid>

            <Dialog
                open={openApproveOrderDialog}
                onClose={handleCloseApproveOrderDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box
                    component="form"
                    onSubmit={handleConfirmApproveOrderDialog}
                >
                    <DialogTitle id="alert-dialog-title" noWrap>
                        {t("message_doYouWantToApproveServiceOrder", { ns: servicesNs })} '{serviceOrderDetails?.number}' ?
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="referenceNumber"
                            name="referenceNumber"
                            label={t("yourOrderNo", { ns: servicesNs })}
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                            <label htmlFor="attachment">
                                <input
                                    accept="text/plain,.doc,.docx,application/msword,image/*,application/pdf"
                                    style={{ display: "none" }}
                                    id="attachment"
                                    name="attachment"
                                    type="file"
                                    multiple={false}
                                    onChange={handleImageChange}
                                />

                                {!fileLoaded && <Button
                                    component="span"
                                    variant="contained"
                                    startIcon={<AttachFileIcon />}
                                    color="info"
                                    size="small"
                                    onClick={uploadFile}
                                >
                                    {t("button_attachFile")}
                                </Button> }
                            </label>
                            {fileLoaded && <Typography><strong>{fileSelected?.name}</strong></Typography>}
                            {fileLoaded && <Button
                                component="span"
                                variant="contained"
                                startIcon={<ClearIcon />}
                                color="error"
                                size="small"
                                onClick={deleteFile}
                            >
                                {t("button_deleteFile")}
                        </Button> }
                    </DialogContent>
                    <DialogActions>

                        <Button variant="contained"
                                color="error"
                                size="medium"
                                startIcon={<CancelIcon/>}
                                onClick={handleCancelApproveOrderDialog}>
                            {t("button_cancel")}
                        </Button>
                        <Button type="submit"
                                variant="contained"
                                color="success"
                                size="medium"
                                startIcon={<DoneIcon/>}
                                autoFocus>
                            {t("button_confirm")}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>


                <Dialog
                    open={openRejectOrderDialog}
                    onClose={handleCloseRejectOrderDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box
                        component="form"
                        onSubmit={handleConfirmRejectOrderDialog}
                    >
                    <DialogTitle id="alert-dialog-title">
                        {t("message_doYouWantToRejectServiceOrder", { ns: servicesNs })} '{serviceOrderDetails?.number}' ?
                    </DialogTitle>
                    <DialogContent>
                        <InputLabel id="demo-simple-select-label">{t('reason', { ns: servicesNs })}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="reason"
                            name="reason"
                            value={rejectedReason}
                            label={t('reason', { ns: servicesNs })}
                            onChange={handleChangeRejectedReason}
                            fullWidth
                            required
                        >
                            {rejectedReasons.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained"
                                color="error"
                                startIcon={<CancelIcon/>}
                                onClick={handleCancelRejectOrderDialog}>
                            {t("button_cancel")}
                        </Button>
                        <Button type="submit"
                                variant="contained"
                                color="success"
                                startIcon={<DoneIcon/>}
                                autoFocus>
                            {t("button_confirm")}
                        </Button>
                    </DialogActions>
                    </Box>
                </Dialog>




            <Snackbar open={openSuccessSnackbar} autoHideDuration={3000} onClose={handleCloseSuccessSnackbar}>
                <MuiAlert onClose={handleCloseSuccessSnackbar} severity="success" sx={{ width: '100%' }}>
                    {t("message_serviceOrderHasBeenUpdated", { ns: servicesNs })}
                </MuiAlert>
            </Snackbar>

            <Snackbar open={openErrorSnackbar} autoHideDuration={3000} onClose={handleCloseErrorSnackbar}>
                <MuiAlert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {t("message_error")}
                </MuiAlert>
            </Snackbar>

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}