import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import { FormEvent, useState } from 'react';
import { PortalCopyright } from '../shared/portal-copyright';
import { HttpClient } from '../shared/http-client';
import { UserInfo } from './UserInfo';
import { AuthenticationService } from '../shared/authentication-service';
import { AuthenticationResult } from './authentication/authenticationResult';
import {useNavigate} from "react-router-dom";
import {Backdrop, CircularProgress, Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {PortalLogo} from "../shared/portal-logo";
import {PortalCircularProgress} from "../shared/portal-circular-progress";


interface AuthenticateRequest {
    login: string;
    password: string;
}

const theme = createTheme();

export function LoginForm() {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation()

    let navigate = useNavigate();

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        setIsLoading(true);

        const data = new FormData(event.currentTarget);
        const request: AuthenticateRequest = {
            login: data.get('login') as string,
            password: data.get('password') as string,
        };

        HttpClient.post<AuthenticationResult>('user-access/account/authenticate', JSON.stringify(request))
            .then(data => {
                AuthenticationService.authenticate(data);
                getUserDetails();
                navigate(`/`);
            })
            .catch(() => {
                setErrorMessage('Invalid username or password');
                setOpenErrorSnackbar(true);
            })
            .finally(() => {
                setIsLoading(false);
            })

        event.preventDefault();
    }

    function getUserDetails(): void {
        HttpClient.get<UserInfo>("user-access/users/me")
            .then(_ => AuthenticationService.setUserInfo(_))
            .catch(_ => { })
    }

    function handleCloseSnackbar(event?: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorSnackbar(false);
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <PortalLogo height={120}/>
                    <Typography component="h1" variant="h5">
                        {t("signIn")}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="login"
                            label={t("login")}
                            name="login"
                            autoComplete="login"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t("password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t("signIn")}
                        </Button>
                        <Grid container>
                            <Grid item>
                                <Link to="/account/forgot-password" >
                                    {t("forgotPasswordQst")}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/*<PortalCopyright sx={{ mt: 8, mb: 4 }} />*/}

                <Snackbar open={openErrorSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                    <MuiAlert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </MuiAlert>
                </Snackbar>

                <PortalCircularProgress isLoading={isLoading}/>

            </Container>
        </ThemeProvider>
    );
}