import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {Link, Outlet} from "react-router-dom";
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { PortalCopyright } from './portal-copyright';
import {PortalLanguageSwitcher} from "./portal-language-switcher";
import {useTranslation} from "react-i18next";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TaskIcon from '@mui/icons-material/Task';
import ListItemText from "@mui/material/ListItemText";
import Construction from "@mui/icons-material/Construction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {useTheme} from "@mui/material";
import * as locales from '@mui/material/locale';
import {useEffect, useState} from "react";
import {PortalAvatar} from "./portal-avatar";
import {AuthenticationService} from "./authentication-service";
import {UserInfo} from "../login/UserInfo";
import {PortalBreadcrumbs} from "./portal-breadcrumbs";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Article, Business, RequestQuote} from "@mui/icons-material";
import {ApplicationSettings} from "../configuration/application-settings";
import { PortalLogo } from './portal-logo';

const drawerWidth: number = 270;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);


type SupportedLocales = keyof typeof locales;

function PortalContent() {
    const [locale, setLocale] = useState<SupportedLocales>("enUS");
    const [open, setOpen] = useState(true);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const {t, i18n} = useTranslation()
    const servicesNs = 'services';
    const catalogsNs = 'catalogs';
    const documentsNs = 'documents';
    const quotesNs = 'quotes';
    const ordersNs = 'orders';

    const theme = useTheme();
    const documentsModuleActive = ApplicationSettings.DocumentsModuleActive;
    const catalogModuleActive = ApplicationSettings.CatalogModuleActive;
    const servicesModuleActive = ApplicationSettings.ServicesModuleActive
    const quotesModuleActivate = ApplicationSettings.QuotesModuleActivate;
    const ordersModuleActivate = ApplicationSettings.OrdersModuleActivate;

    const themeWithLocale = React.useMemo(
        () => createTheme({
            palette: {
                primary: { main: "#12365B" },
                secondary: { main: "#7281205" },
                grey: {
                    "100": "#C1CAD6"
                },
                background: {
                    default: "#235236236",
                    paper: "#fff"
                },
                action: {
                    hover: "#12365B"
                }
            },
            typography: {
                fontFamily: [
                    'MuseoSans300',
                    'sans-serif'
                ].join(',')
            },
            components: {
                MuiTableHead: {
                  styleOverrides: {
                      root: {
                          backgroundColor: '#FAFAFA',
                      }
                  }
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            '&.MuiTableRow-hover:hover': {
                                cursor: 'pointer',
                                backgroundColor: "#C1CAD6"
                            }
                        }
                    }
                },
                MuiLink: {
                    styleOverrides: {
                        root: {
                            "&:hover:not(.Mui-disabled)": {
                                cursor: 'pointer'
                            }
                        }
                    }
                }
            }
        },
        locales[locale]),
        [locale, theme],
    );



    const MenuLink = styled(Link)({
        textDecoration: "none",
        color: 'black',
        ":hover": {
            color: "white"
        }
    });

    const MenuItemText = styled(ListItemText)({

    }) as typeof  ListItemText;

    const toggleDrawer = () => {
        setOpen(!open);
    };
    useEffect(() => {
        setUserInfo(AuthenticationService.getUserInfo())
        switch (i18n.language.toString().toLowerCase()) {
            case "pl" :
                setLocale("plPL");
                break;
            case "en":
                setLocale("enUS");
                break;
        }
    }, []);

    return (
        <ThemeProvider theme={themeWithLocale}>
            <Box sx={{ display: 'flex' }}>
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            color="inherit"
                            fontFamily="MuseoSans700"
                            fontSize="1.25em"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            {t("title").toUpperCase()}
                        </Typography>

                        {/*<IconButton color="inherit">*/}
                        {/*    <Badge badgeContent={4} color="secondary">*/}
                        {/*        <NotificationsIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}

                        <Typography
                            component="span"
                            color="inherit"
                            sx={{ mr: 2 }}
                            style={{ fontWeight: 600 }}
                        >
                            {userInfo?.fullName}
                        </Typography>

                        <PortalLanguageSwitcher/>

                        <PortalAvatar/>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <PortalLogo height={60}/>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List>
                        <MenuLink to="/dashboard">
                            <ListItem button>
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <MenuItemText primary={t("menu_dashboard").toUpperCase()} />
                            </ListItem>
                        </MenuLink>
                        {/*<MenuLink to="my-company">*/}
                        {/*    <ListItem button>*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <Business />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="My Company" />*/}
                        {/*    </ListItem>*/}
                        {/*</MenuLink>*/}
                    </List>
                    <Divider />

                    { catalogModuleActive && <>
                        <List>
                            <ListSubheader inset>{t("menu_title", { ns: catalogsNs }).toUpperCase()}</ListSubheader>
                            <MenuLink to="/catalog/products">
                                <ListItem button>
                                    <ListItemIcon>
                                        <Construction/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("menu_products", { ns: catalogsNs })}/>
                                </ListItem>
                            </MenuLink>
                        </List>
                        <Divider />
                    </> }

                    {quotesModuleActivate && <>
                        <List>
                            <ListSubheader inset>{t("menu_title", { ns: quotesNs }).toUpperCase()}</ListSubheader>
                            <MenuLink to="/quotes/quote-requests">
                                <ListItem button>
                                    <ListItemIcon>
                                        <Construction/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("menu_myQuoteRequests", { ns: quotesNs })}/>
                                </ListItem>
                            </MenuLink>
                        </List>
                    </>}

                    { ordersModuleActivate && <>
                        <List>
                            <ListSubheader inset>{t("menu_title", { ns: ordersNs }).toUpperCase()}</ListSubheader>
                            <MenuLink to="/orders/my-orders">
                                <ListItem button>
                                    <ListItemIcon>
                                        <Construction/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("menu_myOrders", { ns: ordersNs })}/>
                                </ListItem>
                            </MenuLink>
                        </List>
                    </>}

                    { servicesModuleActive && <>
                        <List>
                            <ListSubheader inset>{t("menu_title", { ns: servicesNs }).toUpperCase()}</ListSubheader>
                            <MenuLink to="/services/devices">
                                <ListItem button>
                                    <ListItemIcon>
                                        <Construction/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("menu_serviceItems", { ns: servicesNs })}/>
                                </ListItem>
                            </MenuLink>
                            <MenuLink to="/services/substitute-devices">
                                <ListItem button>
                                    <ListItemIcon>
                                        <FindReplaceIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("menu_substituteDevices", { ns: servicesNs })}/>
                                </ListItem>
                            </MenuLink>
                            <MenuLink to="/services/service-order-requests">
                                <ListItem button>
                                    <ListItemIcon>
                                        <ReportProblemIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("menu_serviceOrderRequests", { ns: servicesNs })}/>
                                </ListItem>
                            </MenuLink>
                            <MenuLink to="/services/service-orders">
                                <ListItem button>
                                    <ListItemIcon>
                                        <TaskIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("menu_serviceOrders", { ns: servicesNs })}/>
                                </ListItem>
                            </MenuLink>
                            <MenuLink to="/services/service-invoices">
                                <ListItem button>
                                    <ListItemIcon>
                                        <ReceiptIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("menu_serviceInvoices", { ns: servicesNs })}/>
                                </ListItem>
                            </MenuLink>
                            {/*<Link to="/services/credit-memos">*/}
                            {/*    <ListItem>*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <RequestPageIcon/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary={t("menu_creditMemos", { ns: servicesNs })}/>*/}
                            {/*    </ListItem>*/}
                            {/*</Link>*/}
                        </List>
                        <Divider />
                    </>}

                    { documentsModuleActive && <>
                        <List>
                            <ListSubheader inset>{t("menu_title", { ns: documentsNs }).toUpperCase()}</ListSubheader>
                            {/*<MenuLink to="/documents/quotes">*/}
                            {/*    <ListItem button>*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <RequestQuote />*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary={t("menu_quotes", { ns: documentsNs })}/>*/}
                            {/*    </ListItem>*/}
                            {/*</MenuLink>*/}
                            {/*<MenuLink to="/documents/orders">*/}
                            {/*    <ListItem button>*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Article />*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary={t("menu_orders", { ns: documentsNs })}/>*/}
                            {/*    </ListItem>*/}
                            {/*</MenuLink>*/}
                            <MenuLink to="/documents/invoices">
                                <ListItem button>
                                    <ListItemIcon>
                                        <Article />
                                    </ListItemIcon>
                                    <ListItemText primary={t("menu_invoices", { ns: documentsNs })}/>
                                </ListItem>
                            </MenuLink>
                            <MenuLink to="/documents/credit-memos">
                                <ListItem button>
                                    <ListItemIcon>
                                        <Article />
                                    </ListItemIcon>
                                    <ListItemText primary={t("menu_creditMemos", { ns: documentsNs })}/>
                                </ListItem>
                            </MenuLink>
                            {/*<MenuLink to="/documents/payments">*/}
                            {/*    <ListItem button>*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Article />*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary={t("menu_payments", { ns: documentsNs })}/>*/}
                            {/*    </ListItem>*/}
                            {/*</MenuLink>*/}
                        </List>
                        <Divider/>
                    </>}

                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: "#F6F4F4",
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12}>
                            <Container>
                                <PortalBreadcrumbs />
                            </Container>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Outlet />
                        </Grid2>
                    </Grid2>
                    {/*<PortalCopyright sx={{ pt: 4 }} />*/}
                </Box>
            </Box>

        </ThemeProvider>
    );
}

export function PortalLayout() {
    return <PortalContent />;
}