import { Box, Typography } from "@mui/material";
import React  from "react";
import {useTranslation} from "react-i18next";
import {CustomerDetails} from "./customer-details";

export function CustomerInfo ({props}: { props: React.PropsWithChildren<CustomerDetails> }) {
    const {t} = useTranslation()

    return(
        <Box
            bgcolor="white"
            sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                height: 220,
                border: 1,
                borderColor: "#DDE2E9"
            }}
        >
            <Box bgcolor="primary.main">
                <Typography
                    component="h6"
                    variant="h6"
                    color="white"
                    sx={{p: 1}}
                >
                    {t("dashboard_yourData").toUpperCase()}
                </Typography>
            </Box>
            <Box sx={{p: 1}}>
                <Typography component="p" variant="h5">
                    {props?.billToName}{props?.billToName2}
                </Typography>
                <Typography
                    color="text.secondary"
                    sx={{ flex: 1 }}
                    style={{whiteSpace: 'pre-line'}}
                >
                    <Typography>
                        {props?.billToAddress}{props?.billToAddress2}
                    </Typography>
                    <Typography>
                        {props?.billToPostCode} {props?.billToCity}
                    </Typography>
                    { props?.vatNo && <Typography>
                        {t('vatNo')}: {props?.vatNo}
                    </Typography> }
                    <Typography>
                        {props?.no}
                    </Typography>
                </Typography>
            </Box>
        </Box>
    )
}