import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {HttpClient} from "../../../shared/http-client";
import {PagedResponse} from "../../../shared/paged-response";
import Container from "@mui/material/Container";
import Grid2 from "@mui/material/Unstable_Grid2";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {FormatDate} from "../../../Utils/utils";
import {StatusChip} from "../../../shared/status-chip";
import {TableFooter, TablePagination} from "@mui/material";
import {PortalCard} from "../../../shared/portal-card";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";

interface Order {
    id: string;
    slug: string;
    status: string;
    statusUpdatedAt: Date;
    number: string;
    referenceNumber: string;
    orderDate: Date;
}

export function MyOrdersList() {
    const [response, setResponse] = useState<PagedResponse<Array<Order>>>();
    const [isLoading, setIsLoading] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchParams] = useSearchParams();
    const {t} = useTranslation();
    const rowsPerPageOptions = [20, 40, 60];
    const initialPageNumber = 0;
    const ordersNs = 'orders';
    let navigate = useNavigate();

    function ShowDetails(slug: string) {
        navigate(`/orders/my-orders/${slug}`,);
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
        load(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(initialPageNumber);
        load(pageNumber, newRowsPerPage);
    };

    function load(page: number, pageSize: number) {
        setIsLoading(true);

        page += 1;

        let requestUrl = `orders/orders/my?pageNumber=${page}&pageSize=${pageSize}`;

        //§requestUrl += `&orderBy=${order}`;

        setIsLoading(true);

        HttpClient.get<PagedResponse<Array<Order>>>(requestUrl)
            .then(_ => {
                setResponse(_);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        load(pageNumber, rowsPerPage);
    }, [])

    return(
        <Container>
            <PortalCard
                title={t("title_myOrders", { ns: ordersNs}).toUpperCase()}
                children={
                    <Grid2 xs={12}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontWeight: 900}}>{t("number", { ns: ordersNs })}</TableCell>
                                        <TableCell sx={{fontWeight: 900}}>{t("referenceNumber", { ns: ordersNs })}</TableCell>
                                        <TableCell sx={{fontWeight: 900}}>{t("orderDate", { ns: ordersNs })}</TableCell>
                                        <TableCell sx={{fontWeight: 900}}>{t("status", { ns: ordersNs })}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {response?.data.map((item) =>
                                        <TableRow
                                            onClick={() => ShowDetails(item.slug)}
                                            key={item.id}
                                            hover={true}
                                        >
                                            <TableCell>{item.number}</TableCell>
                                            <TableCell>{item.referenceNumber}</TableCell>
                                            <TableCell>{FormatDate(item.orderDate)}</TableCell>
                                            <TableCell><StatusChip props={{status : item?.status!}}/></TableCell>
                                            <TableCell>{item.status}</TableCell>
                                            <TableCell>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={response?.meta.totalRecords || 0}
                                            rowsPerPage={rowsPerPage}
                                            page={pageNumber}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            rowsPerPageOptions={rowsPerPageOptions}
                                            labelRowsPerPage={<span>Rows:</span>}
                                            labelDisplayedRows={({ page }) => {
                                                return `Page: ${page}`;
                                            }}
                                            backIconButtonProps={{
                                                color: "secondary"
                                            }}
                                            nextIconButtonProps={{ color: "secondary" }}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "page number"
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid2>
                }
            />

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}