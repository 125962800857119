
import Container from '@mui/material/Container';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {FormatDate, FormatTotal} from "../../../Utils/utils";
import {TableFooter, TablePagination} from "@mui/material";
import React, {useState} from "react";
import {PagedResponse} from "../../../shared/paged-response";
import {useTranslation} from "react-i18next";
import {HttpClient} from "../../../shared/http-client";
import {PortalCard} from "../../../shared/portal-card";
import Box from "@mui/material/Box";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";

interface CreditMemo {
    id: string;
    slug: string;
    number: string;
    description: string;
    documentDate: Date;
    postingDate: Date;
    dueDate: Date;
    currencyCode: string;
    amount: number;
    vatAmount: number;
    amountInclVat: number;
}

export function CreditMemoList() {
    const [response, setResponse] = useState<PagedResponse<Array<CreditMemo>>>();
    const [isLoading, setIsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const rowsPerPageOptions = [20, 40, 60];
    const initialPageNumber = 0;

    const {t} = useTranslation()
    const documentsNs = 'documents';


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
        loadDocuments(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(initialPageNumber);
        loadDocuments(pageNumber, newRowsPerPage);
    };

    function loadDocuments(page: number, pageSize: number) {
        setIsLoading(true);

        page += 1;

        let requestUrl = `documents/credit-memos/my-company?pageNumber=${page}&pageSize=${pageSize}`;
        HttpClient.get<PagedResponse<Array<CreditMemo>>>(requestUrl)
            .then(_ => {
                setResponse(_);
                setIsLoading(false);
            })
            .catch(_ => {
                setIsLoading(false);
            })
    }

    return (
        <Container>
            <PortalCard
                title={t("creditMemos", { ns: documentsNs })}
                    children={
                    <Box>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("no", { ns: documentsNs })}</TableCell>
                                        <TableCell>{t("documentDate", { ns: documentsNs })}</TableCell>
                                        <TableCell>{t("total", { ns: documentsNs })}</TableCell>
                                        <TableCell>{t("action", { ns: documentsNs })}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {response?.data.map((item) =>
                                        <TableRow
                                            key={item.id}
                                        >
                                            <TableCell>{item.number}</TableCell>
                                            <TableCell>{FormatDate(item.documentDate)}</TableCell>
                                            <TableCell>{FormatTotal(item.amountInclVat, item.currencyCode)}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={response?.meta.totalRecords || 0}
                                            rowsPerPage={rowsPerPage}
                                            page={pageNumber}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            rowsPerPageOptions={rowsPerPageOptions}
                                            labelRowsPerPage={<span>Rows:</span>}
                                            labelDisplayedRows={({ page }) => {
                                                return `Page: ${page}`;
                                            }}
                                            backIconButtonProps={{
                                                color: "secondary"
                                            }}
                                            nextIconButtonProps={{ color: "secondary" }}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "page number"
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            />

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}