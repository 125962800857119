import Container from "@mui/material/Container";
import {useEffect, useState} from "react";
import {PagedResponse} from "../../../shared/paged-response";
import {useNavigate, useSearchParams} from "react-router-dom";
import {HttpClient} from "../../../shared/http-client";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {StatusChip} from "../../../shared/status-chip";
import * as React from "react";
import {FormatTotal} from "../../../Utils/utils";
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TableFooter,
    TablePagination,
    TextField
} from "@mui/material";
import {PortalCard} from "../../../shared/portal-card";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import Grid2 from "@mui/material/Unstable_Grid2";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";

interface ServiceOrder {
    id: string;
    slug: string;
    number: string;
    referenceNumber: string;
    status: string;
    orderDateTime: Date;
    currencyCode: string;
    amount: number;
    amountInclVat: number;
}

const statuses = [
    'new',
    'pending approval',
    'approved',
    'rejected',
    'in process',
    'finished',
    'on hold'
]
export function ServiceOrderList() {
    const [response, setResponse] = useState<PagedResponse<Array<ServiceOrder>>>();
    const [isLoading, setIsLoading] = useState(false);
    const [orderBy, setOrderBy] = useState('order_date_desc');
    const [orderNoFilter, setOrderNoFilter] = useState('');
    const [orderStatusFilter, setOrderStatusFilter] = useState<string[]>([]);

    const {t} = useTranslation()
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const rowsPerPageOptions = [20, 40, 60];
    const [searchParams] = useSearchParams();
    const servicesNs = 'services';
    const initialPageNumber = 0;

    useEffect(() => {
        const statusFilter = searchParams.get('status');
        if (statusFilter) {
            setOrderStatusFilter(statusFilter.split(','));
        }
        load(pageNumber, rowsPerPage, orderNoFilter, statusFilter, orderBy);
    }, [])

    let navigate = useNavigate();

    function load(page: number,
                  pageSize: number,
                  orderNo: string | null,
                  status: string | null,
                  order: string) {

        setIsLoading(true);

        page += 1;
        let requestUrl = `services/service-orders/my-company?pageNumber=${page}&pageSize=${pageSize}`;

        if(orderNo) {
            requestUrl += `&orderNumber=${orderNo}`
        }

        if (status) {
            requestUrl += `&status=${status}`
        }

        requestUrl += `&orderBy=${order}`

        HttpClient.get<PagedResponse<Array<ServiceOrder>>>(requestUrl)
            .then(_ => {
                setResponse(_)
                setIsLoading(false);
            })
            .catch(_ => {
                setIsLoading(false);
            })
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
        load(newPage, rowsPerPage, orderNoFilter, orderStatusFilter.join(','), orderBy);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(initialPageNumber);
        load(pageNumber, newRowsPerPage, orderNoFilter, orderStatusFilter.join(','), orderBy);
    };

    const handleChangeOrderBy = (event: SelectChangeEvent) => {
        setOrderBy(event.target.value as string);
        load(pageNumber, rowsPerPage, orderNoFilter, orderStatusFilter.join(','), event.target.value as string);
    };

    function handleChangeOrderNoFilter(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setOrderNoFilter(event.target.value as string)
    }
    const handleChangeStatusFilter = (event: SelectChangeEvent<typeof orderStatusFilter>) => {
        const {
            target: { value },
        } = event;
        setOrderStatusFilter(
            typeof value === 'string' ? value.split(',') : value
        );
        load(pageNumber, rowsPerPage, orderNoFilter, (value as Array<string>).join(','), orderBy)
    };

    function ShowDetails(serviceOrderSlug: string) {
        navigate(`/services/service-orders/${serviceOrderSlug}`,);
    }

    return(
        <Container>
            <PortalCard
                title={t("serviceOrdersList", { ns: servicesNs }).toUpperCase()}
                children={
                    <Box>
                        <Grid2 container spacing={2} padding={1}>
                                <Grid2 xs={12} sm={6}>
                                    <FormControl>
                                        <TextField
                                            id="search-bar"
                                            className="text"
                                            label={t("number", { ns: servicesNs })}
                                            variant="outlined"
                                            size="small"
                                            value={orderNoFilter}
                                            onChange={handleChangeOrderNoFilter}
                                            InputProps={{
                                                endAdornment: (
                                                            <IconButton onClick={() => load(pageNumber, rowsPerPage, orderNoFilter, orderStatusFilter.join(','), orderBy)}>
                                                                <SearchIcon />
                                                            </IconButton>
                                                    )
                                            }}
                                        />
                                    </FormControl>
                                </Grid2>
                                <Grid2 xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-filter-select-label" size='small'>
                                            Status
                                        </InputLabel>
                                        <Select
                                            labelId="status-filter-select-label"
                                            id="status-filter-select"
                                            multiple
                                            value={orderStatusFilter}
                                            //input={<OutlinedInput label="Name" />}
                                            label="Status"
                                            variant="outlined"
                                            onChange={handleChangeStatusFilter}
                                            size='small'
                                        >
                                            {statuses.map((status) => (
                                                <MenuItem
                                                    key={status}
                                                    value={status}
                                                >
                                                    <StatusChip props={{status : status!}}/>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid2>
                                <Grid2 xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="orderBy-select-label" size='small'>
                                            {t("orderBy", { ns: servicesNs })}
                                        </InputLabel>
                                        <Select
                                            labelId="orderBy-select-label"
                                            id="orderBy-select"
                                            value={orderBy}
                                            label={t("orderBy", { ns: servicesNs })}
                                            variant="outlined"
                                            onChange={handleChangeOrderBy}
                                            size='small'
                                        >
                                            <MenuItem value={'order_date_desc'}>{t("order_date_desc", { ns: "services"})}</MenuItem>
                                            <MenuItem value={'order_date_asc'}>{t("order_date_asc", { ns: "services"})}</MenuItem>
                                            <MenuItem value={'order_number_desc'}>{t("order_number_desc", { ns: "services"})}</MenuItem>
                                            <MenuItem value={'order_number_asc'}>{t("order_number_asc", { ns: "services"})}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid2>

                            <Grid2 xs={12}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: 900}}>{t("number", { ns: servicesNs })}</TableCell>
                                                <TableCell sx={{fontWeight: 900}}>{t("referenceNumber", { ns: servicesNs })}</TableCell>
                                                <TableCell sx={{fontWeight: 900}}>{t("status", { ns: servicesNs })}</TableCell>
                                                <TableCell sx={{fontWeight: 900}}>{t("orderDate", { ns: servicesNs })}</TableCell>
                                                <TableCell sx={{fontWeight: 900}} align="right">{t("net", { ns: servicesNs })}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {response?.data.map((item) =>
                                                <TableRow
                                                    onClick={() => ShowDetails(item.slug)}
                                                    key={item.id}
                                                    hover={true}
                                                >
                                                    <TableCell>{item.number}</TableCell>
                                                    <TableCell>{item.referenceNumber}</TableCell>
                                                    <TableCell><StatusChip props={{status : item?.status!}}/></TableCell>
                                                    <TableCell>{moment(item.orderDateTime).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell align="right">{FormatTotal(item?.amountInclVat, item.currencyCode)} </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    count={response?.meta.totalRecords || 0}
                                                    rowsPerPage={rowsPerPage}
                                                    page={pageNumber}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                    labelRowsPerPage={<span>Rows:</span>}
                                                    labelDisplayedRows={({ page }) => {
                                                        return `Page: ${page}`;
                                                    }}
                                                    backIconButtonProps={{
                                                        color: "secondary"
                                                    }}
                                                    nextIconButtonProps={{ color: "secondary" }}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "page number"
                                                        }
                                                    }}
                                                    showFirstButton={true}
                                                    showLastButton={true}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid2>
                        </Grid2>
                    </Box>
                }
            />

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}