import Container from "@mui/material/Container";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {HttpClient} from "../../shared/http-client";
import {AuthenticationService} from "../../shared/authentication-service";

export function RegistrationConfirmation() {
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        confirm();
    }, [])


    function confirm(){
        setIsLoading(true);

        AuthenticationService.logout(false);

        const id = searchParams.get('id');
        const token = searchParams.get('token');
        const resource = `/user-access/user-registration/${id}/confirm?token=${token}`;

        HttpClient.post(resource, '')
            .then(_ => {
                setIsLoading(false);
                navigate('/');
            })
            .catch(_ => {
                setIsLoading(false);
            })
    }

    return(
        <Container>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}