import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {PortalCopyright} from "../../shared/portal-copyright";
import {Backdrop, CircularProgress} from "@mui/material";
import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ReactComponent as Logo} from "../../logo.svg";
import {HttpClient} from "../../shared/http-client";
import {AuthenticationService} from "../../shared/authentication-service";
import {useTranslation} from "react-i18next";

interface ForgotPasswordRequest {
    email: string;
    resetPasswordLink: string;
}

const theme = createTheme();

export function ForgotPassword() {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation()
    let navigate = useNavigate();

    useEffect(() => {
    }, [])

    const PortalLogo = styled(Logo)({
        width: 350,
        height: 110,
        margin: 0,
        padding: 0
    }) as typeof Logo;

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        setIsLoading(true);

        AuthenticationService.logout(false);

        const data = new FormData(event.currentTarget);
        const request: ForgotPasswordRequest = {
            email: data.get('email') as string,
            resetPasswordLink: `${window.location.origin}/account/reset-password`
        };

        HttpClient.post('user-access/account/forgot-password', JSON.stringify(request))
            .then(data => {
                setIsLoading(false);
                navigate(`/`);
            })
            .catch(() => {
                setIsLoading(false);
                navigate(`/`);
            });

        event.preventDefault();
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <PortalLogo/>
                    <Typography component="h1" variant="h5">
                        {t("forgotPassword")}
                    </Typography>
                    <Typography variant="subtitle1">
                        {t("forgotPasswordIntro")}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("emailAddress")}
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t("button_send")}
                        </Button>
                    </Box>
                </Box>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

            </Container>
        </ThemeProvider>
    );
}