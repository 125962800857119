import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {StatusChip} from "../../../shared/status-chip";
import Container from "@mui/material/Container";
import * as React from "react";
import {useEffect, useState} from "react";
import {PagedResponse} from "../../../shared/paged-response";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import {HttpClient} from "../../../shared/http-client";
import {FormatDate} from "../../../Utils/utils";
import {
     FormControl, InputLabel, Select, SelectChangeEvent, Snackbar, TableFooter, TablePagination
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MuiAlert from '@mui/material/Alert';
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {PortalCard} from "../../../shared/portal-card";
import AddIcon from '@mui/icons-material/Add';
import Grid2 from "@mui/material/Unstable_Grid2";
import SearchIcon from "@mui/icons-material/Search";
import {PortalCircularProgress} from "../../../shared/portal-circular-progress";
import useServiceOrderRequestDialog from "../hooks/useServiceOrderRequestDialog";
import ServiceOrderRequestDialog from "./ServiceOrderRequestDialog";

const statuses = [
    'new',
    'canceled',
    'waiting for device',
    'rejected',
    'accepted'
]

interface ServiceOrderRequest {
    id: string;
    slug: string;
    status: string;
    requestDate: Date;
    referenceNumber: string;
    serviceOrderId: string;
    serviceOrderSlug: string;
    serviceOrderNumber: string;
    description: string;
    createdAt: Date;
    updatedAt: Date;
}

export function ServiceOrderRequestList() {
    const [response, setResponse] = useState<PagedResponse<Array<ServiceOrderRequest>>>();
    const [orderBy, setOrderBy] = useState('request_date_desc');
    const [referenceNoFilter, setReferenceNoFilter] = useState('');
    const [requestStatusFilter, setRequestStatusFilter] = useState<string[]>([]);
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchParams] = useSearchParams();
    const [t] = useTranslation()
    const rowsPerPageOptions = [20, 40, 60];
    const initialPageNumber = 0;
    const servicesNs = 'services';
    let navigate = useNavigate();

    const { serviceOrderRequestDialogIsOpen,
        isLoading,
        showServiceOrderRequestDialog,
        hideServiceOrderRequestDialog,
        setIsLoading,
        setIsLoaded} = useServiceOrderRequestDialog();

    function refreshData(): void {
        getServiceOrderRequests(pageNumber, rowsPerPage, referenceNoFilter, requestStatusFilter.join(','), orderBy);
    }

    function getServiceOrderRequests(page: number, pageSize: number, refNo: string | null, status: string | null, order: string) {
        setIsLoading();

        page += 1;
        let requestUrl = `services/service-order-requests/my-company?pageNumber=${page}&pageSize=${pageSize}`;

        if(refNo) requestUrl += `&referenceNumber=${refNo}`

        if(status) requestUrl += `&status=${status}`

        requestUrl += `&orderBy=${order}`

        HttpClient.get<PagedResponse<Array<ServiceOrderRequest>>>(requestUrl)
            .then(_ => {
                setResponse(_);
                setIsLoaded();
            })
            .catch(_ => {
                setIsLoaded();
            })
    }

    function handleCloseSnackbar(event?: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessSnackbar(false);
    }

    function ShowDetails(serviceOrderRequestSlug: string) {
        navigate(`/services/service-order-requests/${serviceOrderRequestSlug}`,);
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
        getServiceOrderRequests(newPage, rowsPerPage, referenceNoFilter, requestStatusFilter.join(','), orderBy);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(initialPageNumber);
        getServiceOrderRequests(pageNumber, newRowsPerPage, referenceNoFilter, requestStatusFilter.join(','), orderBy);
    };

    const handleChangeOrderBy = (event: SelectChangeEvent) => {
        setOrderBy(event.target.value as string);
        getServiceOrderRequests(pageNumber, rowsPerPage, referenceNoFilter, requestStatusFilter.join(','), event.target.value as string);
    };

    function handleChangeOrderNoFilter(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setReferenceNoFilter(event.target.value as string)
    }
    const handleChangeStatusFilter = (event: SelectChangeEvent<typeof requestStatusFilter>) => {
        const {
            target: { value },
        } = event;
        setRequestStatusFilter(
            typeof value === 'string' ? value.split(',') : value
        );
        getServiceOrderRequests(pageNumber, rowsPerPage, referenceNoFilter, (value as Array<string>).join(','), orderBy)
    };

    useEffect(() => {
        const statusFilter = searchParams.get('status');
        if (statusFilter) {
            setRequestStatusFilter(statusFilter.split(','));
        }

        getServiceOrderRequests(pageNumber, rowsPerPage, referenceNoFilter, statusFilter, orderBy);
    }, [])

    return(
        <Container>

            <Grid container justifyContent="flex-end">
                <Box sx={{mb: 2}}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon ={<AddIcon/>}
                        onClick={showServiceOrderRequestDialog}
                        disabled={isLoading}
                    >
                        {t("button_createServiceRequest", { ns: servicesNs })}
                    </Button>
                </Box>
            </Grid>

            <PortalCard
                title={t("serviceOrderRequestsList", { ns: servicesNs }).toUpperCase()}
                children={
                <Box>
                    <Grid2 container spacing={2} padding={1}>
                        <Grid2 xs={12} sm={6}>

                            <FormControl>
                                <TextField
                                    id="search-bar"
                                    className="text"
                                    label={t("myRequestNumber", { ns: "services" })}
                                    variant="outlined"
                                    size="small"
                                    value={referenceNoFilter}
                                    onChange={handleChangeOrderNoFilter}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => getServiceOrderRequests(pageNumber, rowsPerPage, referenceNoFilter, requestStatusFilter.join(','), orderBy)}>
                                                <SearchIcon />
                                            </IconButton>
                                        )
                                    }}
                                />
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel id="status-filter-select-label" size='small'>
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="status-filter-select-label"
                                    id="status-filter-select"
                                    multiple
                                    value={requestStatusFilter}
                                    label="Status"
                                    variant="outlined"
                                    onChange={handleChangeStatusFilter}
                                    size='small'
                                >
                                    {statuses.map((status) => (
                                        <MenuItem
                                            key={status}
                                            value={status}
                                        >
                                            <StatusChip props={{status : status!}}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel id="orderBy-select-label" size='small'>
                                    {t("orderBy", { ns: "services" })}
                                </InputLabel>
                                <Select
                                    labelId="orderBy-select-label"
                                    id="orderBy-select"
                                    value={orderBy}
                                    label={t("orderBy", { ns: "services" })}
                                    variant="outlined"
                                    onChange={handleChangeOrderBy}
                                    size='small'
                                >
                                    <MenuItem value={'request_date_desc'}>{t("request_date_desc", { ns: "services"})}</MenuItem>
                                    <MenuItem value={'request_date_asc'}>{t("request_date_asc", { ns: "services"})}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>

                        <Grid2 xs={12}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: 900}} width="40%">{t("myRequestNumber", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900}} width="20%">{t("orderNo", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900}} width="20%">{t("status", { ns: servicesNs })}</TableCell>
                                            <TableCell sx={{fontWeight: 900}} width="20%">{t("requestDate", { ns: servicesNs })}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {response?.data.map((item) =>
                                            <TableRow
                                                onClick={() => ShowDetails(item.slug)}
                                                key={item.id}
                                                hover={true}
                                            >
                                                <TableCell>{item.referenceNumber}</TableCell>
                                                <TableCell>{item.serviceOrderNumber}</TableCell>
                                                <TableCell><StatusChip props={{status : item?.status!}}/></TableCell>
                                                <TableCell>{FormatDate(item.requestDate)}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                count={response?.meta.totalRecords || 0}
                                                rowsPerPage={rowsPerPage}
                                                page={pageNumber}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                rowsPerPageOptions={rowsPerPageOptions}
                                                labelRowsPerPage={<span>Rows:</span>}
                                                labelDisplayedRows={({ page }) => {
                                                    return `Page: ${page}`;
                                                }}
                                                backIconButtonProps={{
                                                    color: "secondary"
                                                }}
                                                nextIconButtonProps={{ color: "secondary" }}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "page number"
                                                    }
                                                }}
                                                showFirstButton={true}
                                                showLastButton={true}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid2>
                    </Grid2>
                </Box>
                }
            />

            <ServiceOrderRequestDialog
                serviceOrderRequestDialogIsOpen={serviceOrderRequestDialogIsOpen}
                isLoading={isLoading}
                showServiceOrderRequestDialog={showServiceOrderRequestDialog}
                hideServiceOrderRequestDialog={hideServiceOrderRequestDialog}
                setIsLoading={setIsLoading}
                setIsLoaded={setIsLoaded}
                refreshData={refreshData}/>

            <Snackbar open={openSuccessSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <MuiAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {t("message_serviceRequestHasBeenCreated", { ns: servicesNs })}
                </MuiAlert>
            </Snackbar>

            <PortalCircularProgress isLoading={isLoading}/>


        </Container>
    )
}