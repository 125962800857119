import Container from "@mui/material/Container";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {FormEvent, useEffect, useState} from "react";
import {HttpClient} from "../../shared/http-client";
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {PortalCopyright} from "../../shared/portal-copyright";
import {AuthenticationService} from "../../shared/authentication-service";
import {PortalLogo} from "../../shared/portal-logo";
import {useTranslation} from "react-i18next";
import {PortalCircularProgress} from "../../shared/portal-circular-progress";

const theme = createTheme();

interface ActivateUserRegistrationRequest {
    token: string;
    password: string;
}

export function ActivateAccount() {
    const [isLoading, setIsLoading] = useState(false);
    const [userFullName, setUserFullName] =  useState('');
    const [statement1Accepted, setStatement1Accepted] = useState(false);
    const [statement2Accepted, setStatement2Accepted] = useState(false);
    const [statement3Accepted, setStatement3Accepted] = useState(false);
    const [statement4Accepted, setStatement4Accepted] = useState(false);
    const [id, setId] =  useState('');
    const [token, setToken]  =  useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {t, } = useTranslation()

    const statement1CheckboxHandler = () => { setStatement1Accepted(!statement1Accepted);}
    const statement2CheckboxHandler = () => { setStatement2Accepted(!statement2Accepted);}
    const statement3CheckboxHandler = () => { setStatement3Accepted(!statement3Accepted);}
    const statement4CheckboxHandler = () => { setStatement4Accepted(!statement4Accepted);}

    useEffect(() => {
        setUserFullName(searchParams.get('userFullName')!);
        setId(searchParams.get('id')!)
        setToken(searchParams.get('token')!)
    }, [])

    function activateUserRegistration(event: FormEvent<HTMLFormElement>) {

        setIsLoading(true);

        const data = new FormData(event.currentTarget);
        const request: ActivateUserRegistrationRequest = {
            token: token as string,
            password: data.get('password') as string
        };

        const resource = `user-access/user-registration/${id}/activate`;

        AuthenticationService.logout(false);

        HttpClient.post(resource, JSON.stringify(request))
            .then(_ => {
                setIsLoading(false);
                navigate(`/account/login`);
            })
            .catch(_ => {
                setIsLoading(false);
            });

        event.preventDefault();
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <PortalLogo height={110}/>
                    <br/>
                    <Typography component="h1" variant="h6">
                        {t("hello")} {userFullName} {t("createPasswordAndActivateYourAccount")}
                    </Typography>
                    <Box component="form" onSubmit={activateUserRegistration} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            autoComplete="password"
                            autoFocus
                        />
                        <input type="checkbox" id="agreeStatement1" onChange={statement1CheckboxHandler} />
                        <label htmlFor="agreeStatement1">
                            {t("activateAccount_statement1")}  <a href="/terms-and-conditions" target="_blank">{t("button_seeContent")}</a>
                        </label>
                        <br />
                        <input type="checkbox" id="agreeStatement2" onChange={statement2CheckboxHandler} />
                        <label htmlFor="agreeStatement2">
                            {t("activateAccount_statement2")} <a href="/information-clause" target="_blank">{t("button_seeContent")}</a>
                        </label>
                        <br />
                        <input type="checkbox" id="agreeStatement3" onChange={statement3CheckboxHandler} />
                        <label htmlFor="agreeStatement3">
                            {t("activateAccount_statement3")}
                        </label>
                        <br />
                        <input type="checkbox" id="agreeStatement4" onChange={statement4CheckboxHandler} />
                        <label htmlFor="agreeStatement4">
                            {t("activateAccount_statement4")}
                        </label>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!(statement1Accepted && statement2Accepted && statement3Accepted && statement4Accepted)}
                        >
                            {t("button_activate")}
                        </Button>
                    </Box>
                </Box>

                {/*<PortalCopyright sx={{ mt: 8, mb: 4 }} />*/}

                <PortalCircularProgress isLoading={isLoading}/>

            </Container>
        </ThemeProvider>
    );
}