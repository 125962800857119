import axios from "axios";
import { ApplicationSettings } from '../configuration/application-settings';
import {TokenService} from "./token-service";


const http = axios.create({
    baseURL: ApplicationSettings.API_URL,
    responseType: "json",
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    },
});

http.interceptors.request.use(
    (config: any) => {
        const token = TokenService.getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/account/login"
            && originalConfig.url !== "/account/activate"
            && originalConfig.url !== "/account/registration-confirmation"
            && originalConfig.url !== "/account/reset-password"
            && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const response = await http.post("user-access/tokens/refresh", {
                        accessToken: TokenService.getAccessToken(),
                        refreshToken: TokenService.getRefreshToken()
                    });

                    TokenService.setAccessToken(response.data.token.accessToken);
                    TokenService.setRefreshToken(response.data.token.refreshToken);

                    return http(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }

        if (err.response.status === 400 ||
            err.response.status === 404 ||
            err.response.status === 409 ||
            err.response.status === 422 ) {
                return Promise.reject(err);
        }

        return Promise.reject(err);
    }
);

export default http;
