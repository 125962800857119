import {useLocation, useNavigate} from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import React from "react";
import {Breadcrumbs} from "@mui/material";
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";

export function PortalBreadcrumbs() {
    const [t] = useTranslation()
    const routes = [
        { path: '/', breadcrumb: t('breadcrumb_portal') },
        { path: '/dashboard', breadcrumb: t('breadcrumb_dashboard') },

        { path: '/terms-and-conditions', breadcrumb: t('termsAndConditions') },
        { path: '/consents-and-declarations', breadcrumb: t('consentsAndDeclarations') },

        { path: '/quotes', breadcrumb: t('breadcrumb_quotes')},
        { path: '/quotes/quote-requests', breadcrumb: t('breadcrumb_quoteRequests')},
        { path: '/quotes/quote-requests/:slug', breadcrumb: t('breadcrumb_quoteRequestDetails')},

        { path: '/services', breadcrumb: t('breadcrumb_services') },
        { path: '/services/service-orders', breadcrumb: t('breadcrumb_serviceOrders') },
        { path: '/services/service-orders/:slug', breadcrumb: t('breadcrumb_serviceOrderDetails') },
        { path: '/services/devices', breadcrumb: t('breadcrumb_devices')},
        { path: '/services/devices/:slug', breadcrumb: t('breadcrumb_deviceDetails') },
        { path: '/services/substitute-devices', breadcrumb: t('breadcrumb_substituteDevices') },
        { path: '/services/service-order-requests', breadcrumb: t('breadcrumb_serviceOrderRequests') },
        { path: '/services/service-order-requests/:slug', breadcrumb: t('breadcrumb_serviceOrderRequestDetails') },
        { path: '/services/service-invoices', breadcrumb: t('breadcrumb_serviceInvoices')},
    ]

    const navigate = useNavigate();
    const breadcrumbs = useBreadcrumbs(routes);
    function navigateTo(address: string) {
        navigate(address);
    }

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            separator="›"
            sx={{paddingTop: 2}}
        >
            {breadcrumbs.map(({ match, breadcrumb }) => (
                <Link
                    key={match.pathname}
                    underline="hover"
                    onClick={() => navigateTo(match.pathname)}
                >
                    {breadcrumb}
                </Link>
            ))}
        </Breadcrumbs>
    );
}
