import Dialog from '@mui/material/Dialog';
import React, {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {
    Checkbox,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    Select
} from "@mui/material";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {HttpClient} from "../../../shared/http-client";
import {PagedResponse} from "../../../shared/paged-response";
import Typography from "@mui/material/Typography";
import {AuthenticationService} from "../../../shared/authentication-service";
import {Device} from "../interfaces/Device";
import {CreateNewServiceOrderRequest} from "../interfaces/CreateNewServiceOrderRequest";

interface FormValues {
    referenceNumber: string;
    contactPersonName: string;
    contactPersonPhoneNumber: string;
    contactPersonEmail:string;
    contactInformation: string;
    anotherDeliveryAddress: boolean;
    shipToName: string;
    shipToAddress: string;
    shipToPostCode: string;
    shipToCity: string;
    substituteDeviceNeeded: boolean;
    pickUpMyDevice: boolean;
    device: Device | null
    enterNewDevice:boolean;
    deviceSerialNo: string;
    deviceName: string;
    deviceProductionYear: string;
    oilManufacturer: string;
    oilType: string[];
    usageApplication: string;
    workEnvironment: string[];
    ambientTemperature: string[];
    additionalEquipment: string[];
    faultDescription:string;
    remarks: string;
}

interface ServiceOrderRequestDialogProps {
    serviceOrderRequestDialogIsOpen : boolean;
    isLoading : boolean,
    showServiceOrderRequestDialog: () => void;
    hideServiceOrderRequestDialog: () => void;
    setIsLoading: () => void;
    setIsLoaded: () => void;
    refreshData: () => void;
}

export default function ServiceOrderRequestDialog(props: ServiceOrderRequestDialogProps){
    const [t] = useTranslation()
    const servicesNs = 'services';
    const [devices, setDevices] = useState<Array<Device>>();

    const newDevice: Device = {
        id: 'new',
        slug: '',
        no: '',
        serialNo: 'NOWE',
        name: '',
        name2:'',
        productionYear: 0,
        installationDate: 0,
        lastServiceDate: null,
        status: '',
        noOfPreviousServices: null
    };

    const {
        register,
        watch,
        handleSubmit,
        setValue,
        getValues,
        control,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            referenceNumber: '',
            contactPersonName: '',
            contactPersonPhoneNumber: '',
            contactPersonEmail: '',
            contactInformation: '',
            anotherDeliveryAddress: false,
            shipToName: '',
            shipToAddress: '',
            shipToPostCode: '',
            shipToCity: '',
            device: newDevice,
            enterNewDevice: true,
            deviceSerialNo: '',
            deviceName: '',
            deviceProductionYear: '',
            oilManufacturer: '',
            oilType: [],
            usageApplication: '',
            workEnvironment: [],
            ambientTemperature: [],
            additionalEquipment: [],
            faultDescription: '',
            remarks: ''
        }
    });

    const handleDeviceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedSerialNo = event.target.value as string;
        const selectedDevice = devices?.find((_) => _.serialNo === selectedSerialNo) || null;
        if(selectedDevice && selectedDevice.serialNo !== newDevice.serialNo) {
            setValue('enterNewDevice', false);
            setValue('device', selectedDevice);
            setValue('deviceName', selectedDevice.name);
            setValue('deviceSerialNo', selectedDevice.serialNo);
            setValue('deviceProductionYear', selectedDevice.productionYear?.toString());
        } else  {
            setValue('enterNewDevice', true);
            setValue('device', null);
            setValue('deviceName', '');
            setValue('deviceSerialNo', '');
            setValue('deviceProductionYear', '');
        }
    };

    const onSubmit = (data: FormValues) => {
        const request: CreateNewServiceOrderRequest = {
            referenceNumber: data.referenceNumber,
            contactPersonName: data.contactPersonName,
            contactPersonPhoneNumber: data.contactPersonPhoneNumber,
            contactPersonEmail: data.contactPersonEmail,
            contactInformation: data.contactInformation,
            description: '',
            formField1 : data.anotherDeliveryAddress ? data.shipToName : '',
            formField2 : data.anotherDeliveryAddress ? data.shipToAddress : '',
            formField3 : data.anotherDeliveryAddress ? data.shipToPostCode : '',
            formField4 : data.anotherDeliveryAddress ? data.shipToCity : '',
            formField6 : data.substituteDeviceNeeded.toString(),
            formField7 : data.pickUpMyDevice.toString(),
            formField10 : data.deviceName,
            formField11 : data.deviceSerialNo,
            formField12 : data.deviceProductionYear,
            formField13 : data.oilManufacturer,
            formField14 : data.oilType.join(', '),
            formField15 : data.usageApplication,
            formField16 : data.workEnvironment.join(', '),
            formField17 : data.ambientTemperature.join(', '),
            formField18 : data.additionalEquipment.join(', '),
            formField20 : data.faultDescription,
            formField21 : data.remarks
        }

        HttpClient.post('services/service-order-requests', JSON.stringify(request))
            .then(_ => {
                props.refreshData();
                props.hideServiceOrderRequestDialog();
                //setOpenSuccessSnackbar(true);
                //getServiceOrderRequests(pageNumber, rowsPerPage,referenceNoFilter, requestStatusFilter.join(','), orderBy);
            })
            .catch(_ => console.debug(_))
            .finally(() => props.setIsLoaded)
    };


    const oilTypes = [
        'mineralny',
        'syntetyczny'
    ];

    const workingEnvironments = [
        'czyste',
        'kurz',
        'wilgoć',
        'olej'
    ];

    const  ambientTemperatures = [
        '<30°C',
        '30°C - 49°C',
        '50°C - 70°C',
        '>70°C'
    ]

    const additionalEquipments = [
        'przewód elektryczny',
        'filtr zewnętrzny',
        'zawór zwrotny',
        'inne',
        'brak'
    ]

    function onOpen(){
        const userInfo = AuthenticationService.getUserInfo();
        setValue('contactPersonName', userInfo?.fullName!);
        setValue('contactPersonEmail', userInfo?.email!);

        let devices: Array<Device> = [newDevice];

        let requestUrl = `services/devices/my-company?pageNumber=1&pageSize=1000`;

        HttpClient.get<PagedResponse<Array<Device>>>(requestUrl)
            .then(response => {
                devices.push(...response.data.sort((a, b) => a.serialNo.localeCompare(b.serialNo)));
                setDevices(devices)
            })
            .finally(() => {
            })
    }

    useEffect(() => {
        reset();
        onOpen()
    }, [])

    return(
        <Dialog
            open={props.serviceOrderRequestDialogIsOpen}
            fullScreen
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.hideServiceOrderRequestDialog}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        {t("title_newServiceOrderRequest", { ns: servicesNs })}
                    </Typography>
                </Toolbar>
            </AppBar>

            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <TextField
                        label={t("myRequestNumber", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        {...register('referenceNumber')}
                    />
                    <TextField
                        label={t("fullName", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        {...register('contactPersonName', {
                            required: `${t("thisFieldIsRequired", { ns: servicesNs })}`
                        })}
                        error={!!errors.contactPersonName}
                        helperText={errors.contactPersonName?.message}
                        disabled={true}
                    />
                    <TextField
                        label={t("phoneNo", { ns: servicesNs })}
                        margin="dense"
                        type="number"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        {...register('contactPersonPhoneNumber', {
                            required: `${t("thisFieldIsRequired", { ns: servicesNs })}`,
                            pattern: {
                                value: /^\d{9}$/,
                                message: 'Błędny numer telefonu',
                            },
                        })}
                        error={!!errors.contactPersonPhoneNumber}
                        helperText={errors.contactPersonPhoneNumber?.message}
                    />
                    <TextField
                        label={t("email", { ns: servicesNs })}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        {...register('contactPersonEmail', {
                            required: `${t("thisFieldIsRequired", { ns: servicesNs })}`,
                            pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: 'Błądny format adresu email',
                            },
                        })}
                        error={!!errors.contactPersonEmail}
                        helperText={errors.contactPersonEmail?.message}
                        disabled={true}
                    />
                    <TextField
                        label={t("additionalInformation", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        {...register('contactInformation')}
                    />
                    <FormControlLabel
                        label={t("anotherDeliveryAddress", { ns: servicesNs })}
                        control={
                            <Checkbox
                                {...register('anotherDeliveryAddress')}
                                checked={watch('anotherDeliveryAddress')}
                            />
                        }
                    />
                    {watch('anotherDeliveryAddress') && <TextField
                        label={t("name", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        disabled={!watch('anotherDeliveryAddress')}
                        {...register('shipToName')}
                    /> }
                    {watch('anotherDeliveryAddress') && <TextField
                        label={t("street", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        disabled={!watch('anotherDeliveryAddress')}
                        {...register('shipToAddress')}
                    />}
                    {watch('anotherDeliveryAddress') && <TextField
                        label={t("postCode", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        disabled={!watch('anotherDeliveryAddress')}
                        {...register('shipToPostCode')}
                    />}
                    {watch('anotherDeliveryAddress') && <TextField
                        label={t("city", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        disabled={!watch('anotherDeliveryAddress')}
                        {...register('shipToCity')}
                    />}

                    <FormControl error={!!errors.device} fullWidth>
                        <InputLabel>{t("device", { ns: servicesNs })}</InputLabel>
                        <Select
                            input={<OutlinedInput label="Serial No." />}
                            style={{minWidth: 250}}
                            {...register('device', {
                                required: `${t("thisFieldIsRequired", { ns: servicesNs })}`,
                                onChange: handleDeviceChange
                            })}
                            defaultValue={newDevice.serialNo}

                        >
                            {devices?.map(_ => (
                                <MenuItem key={_.serialNo} value={_.serialNo}>
                                    <ListItemText primary={_.serialNo} secondary={_.name}  />
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.device && (
                            <FormHelperText>{errors.device.message}</FormHelperText>
                        )}
                    </FormControl>
                    {watch('enterNewDevice') &&
                        <TextField
                            label={t("serialNo", { ns: servicesNs })}
                            margin="dense"
                            type="text"
                            fullWidth
                            variant="outlined"
                            inputProps={{ spellCheck: 'false' }}
                            disabled={!watch('enterNewDevice')}
                            {...register('deviceSerialNo', {
                                required: `${t("thisFieldIsRequired", { ns: servicesNs })}`
                            })}
                            error={!!errors.deviceSerialNo}
                            helperText={errors.deviceSerialNo?.message}
                        />}
                    {watch('enterNewDevice') &&
                        <TextField
                            label={t("serviceItemType", { ns: servicesNs })}
                            margin="dense"
                            type="text"
                            fullWidth
                            variant="outlined"
                            inputProps={{ spellCheck: 'false' }}
                            disabled={!watch('enterNewDevice')}
                            {...register('deviceName', {
                                required: `${t("thisFieldIsRequired", { ns: servicesNs })}`
                            })}
                            error={!!errors.deviceName}
                            helperText={errors.deviceName?.message}
                        />}
                    <TextField
                        label={t("productionYear", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        {...register('deviceProductionYear',{
                            required: `${t("thisFieldIsRequired", { ns: servicesNs })}`
                        })}
                        value={watch('deviceProductionYear')?.toString()}
                        error={!!errors.deviceProductionYear}
                        helperText={errors.deviceProductionYear?.message}
                    />
                    <TextField
                        label={t("oilManufacturer", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        {...register('oilManufacturer')}
                    />
                    <InputLabel>{t("oilType", { ns: servicesNs })}</InputLabel>
                    <Controller
                        {...register('oilType')}
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <Select
                                multiple
                                {...field}
                                renderValue={(selected) => (selected as string[]).join(', ')}
                                onChange={(e) => field.onChange(e.target.value as string[])}
                                onBlur={field.onBlur}
                            >
                                {oilTypes.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Checkbox checked={field.value?.includes(option)} />
                                        <ListItemText primary={option} />
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    <TextField
                        label={t("usageApplication", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        {...register('usageApplication', {
                            required: `${t("thisFieldIsRequired", { ns: servicesNs })}`
                        })}
                        error={!!errors.usageApplication}
                        helperText={errors.usageApplication?.message}
                    />
                    <InputLabel>{t("workingEnvironment", { ns: servicesNs })}</InputLabel>
                    <Controller
                        {...register('workEnvironment', {
                            required: `${t("thisFieldIsRequired", { ns: servicesNs })}`
                        })}
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <>
                                <Select
                                    multiple
                                    {...field}
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                    onChange={(e) => field.onChange(e.target.value as string[])}
                                    onBlur={field.onBlur}
                                    error={!!errors.workEnvironment}
                                >

                                    {workingEnvironments.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={field.value?.includes(option)} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!!errors.workEnvironment && <FormHelperText error={true}> {errors.workEnvironment?.message}</FormHelperText> }
                            </>
                        )}
                    />
                    <InputLabel>{t("ambientTemperature", { ns: servicesNs })}</InputLabel>
                    <Controller
                        {...register('ambientTemperature', {
                            required: `${t("thisFieldIsRequired", { ns: servicesNs })}`
                        })}
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <>
                                <Select
                                    multiple
                                    {...field}
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                    onChange={(e) => field.onChange(e.target.value as string[])}
                                    onBlur={field.onBlur}
                                    error={!!errors.ambientTemperature}
                                >
                                    {ambientTemperatures.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={field.value?.includes(option)} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!!errors.ambientTemperature && <FormHelperText error={true}> {errors.ambientTemperature?.message}</FormHelperText> }
                            </>
                        )}
                    />
                    <InputLabel>{t("additionalEquipment", { ns: servicesNs })}</InputLabel>
                    <Controller
                        {...register('additionalEquipment')}
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <Select
                                multiple
                                {...field}
                                renderValue={(selected) => (selected as string[]).join(', ')}
                                onChange={(e) => field.onChange(e.target.value as string[])}
                                onBlur={field.onBlur}
                            >
                                {additionalEquipments.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Checkbox checked={field.value?.includes(option)} />
                                        <ListItemText primary={option} />
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    <TextField
                        label={t("faultDescription", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        rows={4}
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        {...register('faultDescription', {
                            required: `${t("thisFieldIsRequired", { ns: servicesNs })}`
                        })}
                        error={!!errors.faultDescription}
                        helperText={errors.faultDescription?.message}
                    />
                    <FormControlLabel
                        label={t("form_substituteDeviceNeeded", { ns: servicesNs })}
                        control={
                            <Checkbox
                                {...register('substituteDeviceNeeded')}
                                checked={watch('substituteDeviceNeeded')}
                            />
                        }
                    />
                    <br/>
                    <FormControlLabel
                        label={t("form_pickUpMyDevice", { ns: servicesNs })}
                        control={
                            <Checkbox
                                {...register('pickUpMyDevice')}
                                checked={watch('pickUpMyDevice')}
                            />
                        }
                    />
                    <Typography>
                        <strong>WAŻNE:</strong> zgłoszenie awarii nie jest jednoznaczne z zamówieniem wykonania usługi serwisu.
                        Po przesłaniu urządzenia do Becker Polska Sp. z o. o., zostanie przeprowadzona nieodpłatna ekspertyza i przedstawione do akceptacji zlecenie serwisowe, wraz z listą zużytych części, wyceną oraz proponowanym terminem realizacji. Po wykonaniu usługi serwisowej, urządzenie zostanie pomalowane. W przypadku braku akceptacji przedstawionego zlecenia serwisowego, pompa zostanie zwrócona w stanie rozmontowanym na koszt klienta. Przy braku kontaktu ze strony serwisu w terminie do 5 dni roboczych od daty wysyłki, prosimy o niezwłoczny kontakt pod numerem telefonu <strong>65-5114172</strong> lub mailem na adres: <strong>serwis@becker-polska.com.</strong>
                    </Typography>
                    <TextField
                        label={t("remarks", { ns: servicesNs })}
                        margin="dense"
                        type="text"
                        fullWidth
                        rows={4}
                        variant="outlined"
                        inputProps={{ spellCheck: 'false' }}
                        {...register('remarks')}
                    />
                    <Typography display="block">
                        <strong>Świadomy odpowiedzialności karnej, oświadczam, że w/w urządzenie nie jest skażone i jest wolne od substancji toksycznych i radioaktywnych.</strong>
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained"
                            color="error"
                            disabled={props.isLoading}
                            onClick={props.hideServiceOrderRequestDialog}
                    >
                        {t("button_cancel", { ns: servicesNs })}
                    </Button>
                    <Button variant="contained"
                            color="success"
                            type="submit"
                            disabled={props.isLoading}
                    >
                        {t("button_create", { ns: servicesNs })}
                    </Button>
                </DialogActions>

            </form>
        </Dialog>
    )
}