import moment from "moment";


export function FormatAmount(num: number | undefined) {
    if(num){
        return `${num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
    }
    return `0.00`
}

export function FormatTotal(num: number | undefined, currencyCode: string | undefined) {
    let amount : string = "";
    let currencySymbol: string = "";

    if(num) {
        amount =  `${num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
    } else {
        amount = `0.00`
    }

    // if(currencyCode) {
    //     switch (currencyCode.toUpperCase()) {
    //         case "PLN" : {
    //             currencySymbol = "zł";
    //             break;
    //         }
    //         case "EUR" : {
    //             currencySymbol = "€";
    //             break;
    //         }
    //         case "USD" : {
    //             currencySymbol = "$";
    //             break;
    //         }
    //         default : {
    //             currencySymbol = "";
    //             break;
    //         }
    //     }
    // }

    return `${amount} ${currencyCode}`;
}

export function FormatDate(date: Date | undefined | null) {

    let dateString: string = '';

    if(date) {
        dateString =  moment(date).format("DD-MM-YYYY")
    }

    if(dateString === '01-01-0001')
        dateString = '';

    return dateString;
}