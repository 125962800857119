import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {PortalLogo} from "../../shared/portal-logo";
import Typography from "@mui/material/Typography";
import React from "react";
import {useTranslation} from "react-i18next";


export function InformationClause() {
    const [t] = useTranslation();

    return(
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Container>
                <Paper variant="outlined">
                    <br/>
                    <PortalLogo height={120}/>
                    <Typography>
                        <Typography align="center"  variant="h3" sx={{pl: 4, pt: 3}}>{t("informationClause")}</Typography>
                        <Typography sx={{pl: 3, pr: 3, pb: 3}}>
                            <ol type="I">
                                <li><strong>ADMINISTRATOR DANYCH OSOBOWYCH</strong></li>
                                Administratorem danych osobowych jest Becker Polska Sp. z o.o. z siedzibą w:  Pianowo 46, 64-000 Kościan, KRS 0000313602 Sąd Rejonowy Poznań - Nowe Miasto i Wilda, IX Wydział Gospodarczy w Poznaniu, NIP PL6981791144, Kapitał zakładowy 750.000,00 zł (dalej: Spółka lub Administrator).
                                <li><strong>CEL, PODSTAWA PRAWNA I OKRES PRZETWARZANIA DANYCH OSOBOWYCH</strong></li>
                                Spółka przetwarza dane osobowe w celu założenia profilu Klienta i umożliwienia Klientowi korzystania z funkcjonalności tego profilu w związku z udzieloną przez Klienta uprzednią zgodą na przetwarzanie danych osobowych  (na podstawie art. 6 ust. 1 lit. a RODO). Dane osobowe będą przechowywane przez okres maksymalnie 7 lat od momentu wycofania udzielonej zgody.
                                Spółka przetwarza dane Klientów również w celu ustalenia roszczeń, obrony przed nimi i ich dochodzenia. Dane te przetwarzane są do momentu przedawnienia ewentualnych roszczeń w oparciu o obowiązujące przepisy prawa. Podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Spółki (art. 6 ust. 1 lit. f RODO).
                                <li><strong>PRAWA KLIENTÓW ZWIĄZANE Z PRZETWARZANIEM DANYCH OSOBOWYCH PRZEZ SPÓŁKĘ</strong></li>
                                <ol type="1">
                                    <li>Prawo dostępu do treści danych</li>
                                    <li>Prawo do sprostowania danych</li>
                                    <li>Prawo do usunięcia danych, jeżeli:</li>
                                    <ul>
                                        <li>dane osobowe nie są już niezbędne do celów, w których zostały zebrane lub w inny sposób przetwarzane</li>
                                        <li>Klient cofnął zgodę, na podstawie której Spółka przetwarzała jego dane, a nie ma innej podstawy prawnej przetwarzania</li>
                                        <li>Klient wniósł sprzeciw wobec przetwarzania i nie występuje nadrzędnie prawnie uzasadniona podstawa przetwarzania</li>
                                        <li>dane osobowe były przetwarzane niezgodnie z prawem</li>
                                        <li>konieczność usunięcia wynika z obowiązku prawnego</li>
                                    </ul>
                                    <br />

                                </ol>
                                Spółka określa sposób obsługi prawa do usunięcia danych w taki sposób, aby zapewnić efektywną realizację tego prawa przy poszanowaniu wszystkich zasad ochrony danych, w tym bezpieczeństwa, a także weryfikację, czy nie zachodzą wyjątki, o których mowa w RODO.
                                <ol type="1">
                                    <li>Prawo do ograniczenia przetwarzania danych</li>
                                    <li>Prawo do przenoszenia danych</li>
                                    <li>Prawo do wniesienia sprzeciwu</li>
                                    <li>Prawo do cofnięcia zgody w dowolnym momencie. Cofnięcie zgody nie wpływa jednak na wcześniejszą zgodność z prawem przetwarzania danych</li>
                                    <li>Prawo do wniesienia skargi w związku z przetwarzaniem danych osobowych do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych</li>
                                </ol>
                                <li><strong>UDOSTĘPNIANIE DANYCH</strong></li>
                                <ol type="1">
                                    <li>Dane osobowe w razie konieczności mogą być udostępniane osobom trzecim współpracującym z Administratorem w zakresie niezbędnym do wykonania celów współpracy z Klientem</li>
                                    <li>Dane osobowe nie będą podlegać zautomatyzowanemu podejmowaniu decyzji lub profilowaniu.</li>
                                    <li>Dane osobowe nie będą przekazywane poza obszar Unii Europejskiej i EOG.</li>
                                </ol>
                            </ol>


                        </Typography>
                    </Typography>
                </Paper>
            </Container>
        </Box>
    )
}