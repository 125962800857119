import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        ns: ['common', 'quotes', 'orders', 'services', 'documents', 'catalogs', 'statuses'],
        defaultNS: 'common',
        lng: 'pl',
        fallbackLng: 'en',
        //debug: process.env.NODE_ENV === 'development',
        interpolation: {
            // react already safes from xss
            escapeValue: false
        }
    }).then(() => {}) ;
