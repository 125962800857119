import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {HttpClient} from "../../../shared/http-client";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {FormatDate} from "../../../Utils/utils";
import {StatusChip} from "../../../shared/status-chip";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";


interface OrderDetails {
    id: string;
    slug: string;
    number: string;
    referenceNumber: string;
    status: string;
    statusUpdatedAt: Date;
    orderDate: Date;
    requestedDeliveryDate: Date;
    promisedDeliveryDate: Date;
    currencyCode: string;
    buyerRemarks: string;
    sellerRemarks: string;
    contactPersonName: string;
    contactPersonPhoneNumber: string;
    contactPersonEmail: string;
    contactInformation: string;
    items: orderDetailsItem[];
    createdAt: Date;
    updatedAt: Date;
}

interface orderDetailsItem {
    lineNumber: number;
    lineIdentifier: string;
    productId: string;
    productNo: string;
    productSlug: string;
    description: string;
    quantity: number;
    unitOfMeasureCode: string;
    unitPrice: number;
    amount: number;
    vatAmount: number;
    amountInclVat: number;
    buyerRemarks: string;
    sellerRemarks: string;
    requestedDeliveryDate: Date;
    promisedDeliveryDate: Date;
    createdAd: Date;
    updatedAt: Date;
}

export function OrderDetails() {
    const [orderDetails, setOrderDetails] = useState<OrderDetails>();
    const [isLoading, setIsLoading] = useState(false);
    const [t] = useTranslation();
    const { slug } = useParams();
    const ordersNs: string = 'orders';

    function getOrderDetails() {
        setIsLoading(true);
        HttpClient.get<OrderDetails>(`orders/orders/${slug}`)
            .then(_ => {
                setOrderDetails(_);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        getOrderDetails();
    }, [])

    return(
        <Container>
            {/*<Grid container justifyContent="flex-end">*/}
            {/*    <Box sx={{mb: 2}}>*/}
            {/*        <Stack spacing={2} direction="row">*/}
            {/*            /!*<Button*!/*/}
            {/*            /!*    variant="contained"*!/*/}
            {/*            /!*    color="error"*!/*/}
            {/*            /!*    size="medium"*!/*/}
            {/*            /!*    startIcon={<CancelIcon/>}*!/*/}
            {/*            /!*    // disabled={serviceOrderRequestDetails?.status.toUpperCase() !== "NEW"}*!/*/}
            {/*            /!*    // onClick={handleOpenCancelServiceRequestDialog}*!/*/}
            {/*            /!*>*!/*/}
            {/*            /!*    {t("button_cancel")}*!/*/}
            {/*            /!*</Button>*!/*/}
            {/*            <Button*/}
            {/*                variant="contained"*/}
            {/*                color="success"*/}
            {/*                size="medium"*/}
            {/*                startIcon={<PostAddIcon/>}*/}
            {/*                // disabled={serviceOrderRequestDetails?.status.toUpperCase() !== "NEW"}*/}
            {/*                onClick={() => convertToOrder(quoteRequestDetails?.id!)}*/}
            {/*            >*/}
            {/*                {t("button_convertToOrder")}*/}
            {/*            </Button>*/}
            {/*        </Stack>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}
            <Grid>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                    <Grid container spacing={2} >
                        <Grid container item xs={6} direction="column" >
                            <Typography>
                                <strong>{t("number", { ns: ordersNs })}:</strong> {orderDetails?.number}
                            </Typography>
                            <Typography>
                                <strong>{t("referenceNumber", { ns: ordersNs })}:</strong> {orderDetails?.referenceNumber}
                            </Typography>
                            <Typography>
                                <strong>{t("orderDate", { ns: ordersNs })}: </strong> {FormatDate(orderDetails?.orderDate)}
                            </Typography>
                            <Typography>
                                <strong>{t("requestedDeliveryDate", { ns: ordersNs })}: </strong> {FormatDate(orderDetails?.requestedDeliveryDate)}
                            </Typography>
                            <Typography>
                                <strong>{t("status", { ns: ordersNs })}:</strong> <StatusChip props={{status: orderDetails?.status!}} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <br/>

                    <Grid container item xs={6} direction="column" >
                        <Typography>
                            <strong>{t("contactPerson", { ns: ordersNs })}:</strong>
                        </Typography>
                        <Typography>
                            {orderDetails?.contactPersonName}
                        </Typography>
                        <Typography>
                            {orderDetails?.contactPersonPhoneNumber}
                        </Typography>
                        <Typography>
                            {orderDetails?.contactPersonEmail}
                        </Typography>
                        <Typography>
                            {orderDetails?.contactInformation}
                        </Typography>
                    </Grid>
                    <br/>

                    <Grid>
                        <Typography>
                            <strong>{t("buyerRemarks", { ns: ordersNs })}:</strong>
                        </Typography>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {orderDetails?.buyerRemarks}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography>
                            <strong>{t("sellerRemarks", { ns: ordersNs })}:</strong>
                        </Typography>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {orderDetails?.sellerRemarks}
                        </Typography>
                    </Grid>
                    <br/>

                    <Grid>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="5%"><strong>{t("no", { ns: ordersNs })}</strong></TableCell>
                                        <TableCell width="25%"><strong>{t("description", { ns: ordersNs })}</strong></TableCell>
                                        <TableCell width="15%"><strong>{t("quantity", { ns: ordersNs })}</strong></TableCell>
                                        <TableCell width="15%"><strong>{t("unitOfMeasure", { ns: ordersNs })}</strong></TableCell>
                                        <TableCell width="15%"><strong>{t("unitPrice", { ns: ordersNs })}</strong></TableCell>
                                        <TableCell width="15%"><strong>{t("amount", { ns: ordersNs })}</strong></TableCell>
                                        <TableCell width="15%"><strong>{t("amountInclVat", { ns: ordersNs })}</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderDetails?.items.map((item) =>
                                        <TableRow
                                            key={item?.lineNumber}
                                        >
                                            <TableCell>{item?.productNo}</TableCell>
                                            <TableCell>{item?.description}</TableCell>
                                            <TableCell>{item?.quantity}</TableCell>
                                            <TableCell>{item?.unitOfMeasureCode}</TableCell>
                                            <TableCell>{item?.unitPrice}</TableCell>
                                            <TableCell>{item?.amount}</TableCell>
                                            <TableCell>{item?.amountInclVat}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    )
}