import React from "react";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {PortalLogo} from "../../shared/portal-logo";

export function TermsAndConditions(){
    const [t] = useTranslation();

    return(
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Container>
                <Paper variant="outlined" sx={{p: 3}}>
                    <br/>
                    <PortalLogo height={120}/>
                    <Typography>
                        <Typography align="center" variant="h3">{t("termsAndConditions")}</Typography>
                        <Typography>
                          <Typography align="center" variant="h6" fontWeight="600">§1 Definicje</Typography>
                            Przez użyte w niniejszym Regulaminie określenia należy rozumieć:
                            <ol type="1">
                                <li>Becker, Becker Polska - Becker Polska sp. z o.o., Pianowo 46, 64-000 Kościan, NIP: 6981791144, REGON: 300928900, wpisana do Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy, pod numerem KRS 0000313602, kapitał zakładowy w wysokości 750.000,00 złotych;</li>
                                <li>Doręczenia – funkcjonalność Portalu, stanowiąca element Usługi, umożliwiająca Klientowi doręczenie do Becker Polska informacji lub dokumentów, w tym zawierających oświadczenia woli lub wiedzy, w ramach realizacji obowiązków lub uprawnień wynikających z łączącej strony umowy lub realizacji zlecenia, a także umożliwiająca Becker Polska doręczenie analogicznych informacji lub dokumentów do Klienta;</li>
                                <li>Hasło – ciąg znaków podawany przez Klienta w czasie logowania do Portalu Klienta, służących do jednoznacznej identyfikacji Użytkownika. Hasło powinno się składać minimum z 8, a maksimum z 32 znaków i zawierać co najmniej jedną literę (przy czym Portal rozróżnia małe i wielkie litery), cyfrę oraz znak specjalny (kropka, przecinek itp.); hasło nie może zawierać polskich znaków (ę, ą, ł itp.) ani spacji;</li>
                                <li>Klient – każda osoba fizyczna, prawna lub inna osoba posiadająca podmiotowość prawną, której Becker Polska nadała dostęp do Portalu, będąca stroną umowy zawartej z Becker Polska;</li>
                                <li>Login – ciąg znaków nadany Użytkownikowi przez Becker Polska  i przez podawany przez Użytkownika w czasie każdorazowego logowania w Portalu, niezbędny do zalogowania się Użytkownika w serwisie</li>
                                <li>Portal, Portal Klienta – platformę informatyczną dostępną pod adresem: www.portal.becker-polska.com  udostępnianą Klientowi przez Administratora, a następnie umożliwiającą, po adekwatnym zalogowaniu się Użytkownika, utrzymywanie komunikacji z Administratorem w zakresie każdorazowo dostępnych na Portalu funkcjonalności</li>
                                <li>Powiadomienie – powiadomienie o zrealizowaniu z wykorzystaniem Portalu czynności przez lub dla Klienta, w tym m.in. o złożeniu oświadczenia woli lub wiedzy, bądź umieszczenia w Portalu określonej informacji;</li>
                                <li>Usługa – oznacza świadczoną elektronicznie (tj. poprzez wysłanie i odebranie danych za pomocą sieci telekomunikacyjnej, bez jednoczesnej obecności stron), przy wykorzystaniu Portalu Klienta, usługę obsługi Klienta;</li>
                                <li>Ustawa o VAT – ustawa z dnia 11 marca 2004 r. o podatku od towarów i usług;</li>
                                <li>Użytkownik – osoba fizyczna mająca dostęp do danych Klienta prezentowanych na Portalu oraz uprawniona przez Klienta do podejmowania czynności z wykorzystaniem funkcjonalności Portalu, w tym do składania w imieniu i na rzecz Klienta wiążących oświadczeń woli i wiedzy w Portalu;</li>
                                <li>Zgody – wszelkie dobrowolnie wyrażone zgody Klienta lub Użytkownika w związku z przyznaniem im dostępu do Portalu Klienta, w tym konieczne do korzystania z funkcjonalności Usługi  (w szczególności zgoda na udostępnianie dokumentów w postaci elektronicznej przez Becker Polska), a także wszelkie inne zgody, których treść znajduje się w Portalu Klienta.</li>
                            </ol>

                            <Typography align="center" variant="h6" fontWeight="600">§2 Dostęp do Portalu Klienta</Typography>
                            <ol type="1">
                                <li>Celem uzyskania dostępu do Portalu, Klient dokonuje stosownego zgłoszenia logując się do Portalu poprzez link aktywacyjny przesłany na podany przez Klienta adres email.</li>
                                <li>Korzystając z otrzymanego Loginu, Użytkownik loguje się za pośrednictwem otrzymanego linku. Użytkownik wprowadza ustalone przez siebie hasło dostępu do Portalu Klienta. </li>
                                <li>Przy użyciu Loginu i Hasła Użytkownik loguje się do Portalu Klienta.</li>
                                <li>Danymi osobowymi Użytkownika administruje Becker Polska. Zasady przetwarzania danych osobowych i wszelkie informacje związane z ich przetwarzaniem przekazywane są Użytkownikowi w procesie nadawania dostępu do Portalu, bezpośrednio bądź za pośrednictwem Klienta</li>
                                <li>Logowanie Klienta w Portalu Klienta, skutkująca uruchomieniem Usługi poprzedzona wyrażeniem zgody na przestrzeganie Regulaminu, jest równoznaczna z akceptacją jego warunków i zawarciem przez Klienta umowy o świadczenie Usługi drogą elektroniczną z Becker Polska bez konieczności sporządzenia bądź podpisywania odrębnych umów.</li>
                            </ol>

                            <Typography align="center" variant="h6" fontWeight="600">§3 Akceptacja Regulaminu</Typography>
                            <ol type="1">
                                <li>Każdorazowo aktualny Regulamin dostępny jest w Portalu Klienta w sposób, który umożliwia pozyskanie, utrwalenie i odtworzenie jego treści.</li>
                                <li>Przy logowaniu do Portalu, Klient potwierdza znajomość treści Regulaminu oraz akceptuje ją, zobowiązując się jednocześnie do przestrzegania jego postanowień.</li>
                                <li>Regulamin dostępny jest także dla każdego Użytkownika po zalogowaniu się do Portalu. Każdy Użytkownik, jako korzystający z Portalu w imieniu i na rzecz Klienta, zobowiązany jest do przestrzegania jego postanowień.</li>
                                <li>Becker zastrzega sobie prawo zmiany treści Regulaminu. Jeżeli treść Regulaminu podlegać będzie zmianie, Klient zostanie o tym fakcie poinformowany w ten sposób, że nowa treść Regulaminu zostanie mu udostępniona w Portalu (z możliwością pobrania dokumentu). Powyższa notyfikacja zostanie dokonana nie później niż na 14 dni przed datą wejścia w życie zmian treści Regulaminu. Zaakceptowanie nowej treści Regulaminu będzie konieczne dla dalszego korzystania z Portalu Klienta.</li>
                                <li>Klient nie ma obowiązku akceptacji zmienionego Regulaminu. Wobec powyższego Klient po otrzymaniu informacji o nowym Regulaminie ma prawo złożyć adresowane do Becker Polska oświadczenie, w formie pisemnej lub dokumentowej o braku akceptacji jego treści, przy czym oświadczenie takie musi zostać wysłane do Becker Polska w ciągu 7 dni od otrzymania przez Klienta informacji o wprowadzanej zmianie treści Regulaminu. W przypadku skutecznej odmowy akceptacji zmian Regulaminu, zakres Usługi dla takiego Klienta zostanie ograniczony lub dostęp do Portalu zostanie zablokowany. </li>
                                <li>Brak odmowy akceptacji treści nowego Regulaminu oznacza, że Regulamin został zaakceptowany przez Klienta i jest dla niego wiążący. </li>
                            </ol>

                            <Typography align="center" variant="h6" fontWeight="600">§4 Funkcjonalności Portalu Klienta</Typography>
                            <ol type="1">
                                <li>Portal jest internetowym narzędziem umożliwiającym Użytkownikowi skorzystanie z Usług, obejmujących:</li>
                                <ol type="1">
                                    <li>samodzielny dostęp do informacji o wysłanych zgłoszeniach serwisowych, przetwarzanych zleceniach, urządzeniach w posiadaniu, urządzeniach zastępczych oraz fakturach serwisowych Klienta, w tym w szczególności o związanych z nimi rozliczeniach, a także o aktualnych harmonogramach spłat należności wynikających z danego zlecenia;</li>
                                    <li>zarządzanie Zgodami</li>
                                </ol>
                                <li>Becker zastrzega sobie prawo do wprowadzania zmian w funkcjonalnościach Portalu. Zmiany w funkcjonalnościach nie stanowią zmiany Regulaminu.</li>
                                <li>Becker zastrzega sobie prawo do dowolnego, w każdym czasie, ograniczenia, zawieszenia lub zaprzestania świadczenia wszystkich lub niektórych z Usług, o których mowa w ust. 1 bez podawania przyczyny.</li>
                            </ol>

                            <Typography align="center" variant="h6" fontWeight="600">§5 Aspekty techniczne</Typography>
                            <ol type="1">
                                <li>Dostęp do Portalu jest możliwy z większości przeglądarek internetowych, a w szczególności: Chrome w wersji 3.0 i wyższych, Firefox w wersji 3.0 i wyższych, Internet Explorer w wersji 9 i wyższych, Safari w wersji 6 i wyższych, oraz z natywnych przeglądarek Android od wersji Android 4.1. Na innych przeglądarkach lub starszych wersjach Portal może nie działać prawidłowo. Becker Polska zaleca korzystanie z najnowszych wersji przeglądarek</li>
                                <li>Do wykorzystywania pełnej funkcjonalności Portalu niezbędne jest zainstalowanie programu umożliwiającego otwieranie plików zapisanych w formacie PDF, a także włączenie obsługi JavaScript i Cookies w przeglądarce internetowej.</li>
                                <li>Becker nie ponosi odpowiedzialności za problemy techniczne bądź ograniczenia techniczne oprogramowania lub sprzętu komputerowego, z którego korzysta Klient, a które uniemożliwiają lub utrudniają Klientowi korzystanie z Portalu.</li>
                                <li>Portal dostępny jest dla Klientów przez 24 godziny na dobę, przez 7 dni w tygodniu, z wyłączeniem przerw związanych z aktualizacją danych oraz przerw serwisowych.</li>
                                <li>Becker nie ponosi odpowiedzialności za przerwy w dostępie do informacji zawartych w Portalu Klienta, wynikające z awarii lub przypadków wadliwego funkcjonowania systemów teleinformatycznych</li>
                            </ol>

                            <Typography align="center" variant="h6" fontWeight="600">§6 Bezpieczeństwo</Typography>
                            <ol type="1">
                                <li>Użytkownik zobowiązany jest zabezpieczyć Login i Hasło przed ujawnieniem osobom trzecim. W przypadku podejrzenia ujawnienia danych dostępowych Użytkownik zobowiązany są do zmiany Hasła.</li>
                                <li>Klient ponosi całkowitą i wyłączną odpowiedzialność za wszelkie skutki wynikłe z korzystania z Portalu przez osoby nieuprawnione, które w jakikolwiek sposób weszły w posiadanie Loginu i Hasła. W szczególności Becker Polska nie ponosi odpowiedzialności za szkody powstałe w związku z wykorzystaniem informacji uzyskanych za pośrednictwem Portalu Klienta przez osoby niepowołane.</li>
                            </ol>

                            <Typography align="center" variant="h6" fontWeight="600">§7 Powiadomienia i Doręczenia</Typography>
                            <ol type="1">
                                <li>Portal Klienta stanowi kanał informacyjny dotyczący Usług</li>
                                <li>Na podany przez Klienta adres e-mail przesyłane będą Powiadomienia z Portalu Klienta.</li>
                                <li>Zalogowany Użytkownik może wyłączyć otrzymywanie Powiadomień poprzez wysłanie prośby o ich wyłączenie drogą mailową na adres: serwis@becker-polska.com.</li>
                                <li>Wszelkie Doręczenia uznaje się za zrealizowane z chwilą udostępnienia na Portalu informacji lub dokumentu będącego przedmiotem Doręczenia. Jeżeli w ramach Doręczenia Becker składa Klientowi bądź Klient składa do Becker Polska swoje oświadczenie woli, domniemuje się, że doszło ono do odpowiednio Klienta lub Becker Polska z chwilą jego udostępnienia na Portalu.</li>
                                <li>Becker Polska  każdorazowo przekaże Klientowi dodatkowe Powiadomienie o realizacji Doręczenia na Portalu. Powiadomienie takie ma charakter wyłącznie informacyjny, a jego ewentualny brak nie wywołuje nieskuteczności Doręczenia dokonanego na Portalu.</li>
                                <li>Becker Polska informuje i zastrzega, iż materiały prezentowane w Portalu Klienta dotyczące produktów i usług  Becker Polska są podane tylko i wyłącznie w celach informacyjnych i w żadnym wypadku nie stanowią wiążącej oferty w rozumieniu przepisów Kodeksu cywilnego ani nie mogą stanowić podstawy jakichkolwiek roszczeń względem Becker Polska.</li>
                            </ol>

                            <Typography align="center" variant="h6" fontWeight="600">§8 Reklamacje</Typography>
                            <ol type="1">
                                <li>Reklamacje dotyczące Usług można składać</li>
                                <ol type="1">
                                    <li>pisemnie na Becker Polska sp. z o.o. (Pianowo 46, 64-000 Kościan),</li>
                                    <li>drogą elektroniczną na adres email: serwis@becker-polska.com</li>
                                </ol>
                                <li>Reklamacje są rozpatrywane niezwłocznie, zaś odpowiedź na reklamację będzie udzielona nie później niż w terminie 14 dni od dnia jej otrzymania. </li>
                                <li>Pisemną odpowiedź Becker Polska wyśle na adres składającego reklamację wskazany w Portalu jako jego adres do korespondencji albo drogą poczty elektronicznej, jeżeli wniosek o takie doręczenie znajdzie się w treści reklamacji.</li>
                                <li>Zmiana wskazanych adresów lub numeru telefonu nie stanowi zmiany treści Regulaminu, jednak Becker Polska każdorazowo poinformuje Klienta o takiej zmianie</li>
                            </ol>

                            <Typography align="center" variant="h6" fontWeight="600">§9 Postanowienia końcowe</Typography>
                            <ol type="1">
                                <li>Materiały dostępne na Portalu Klienta mogą zawierać materiały chronione prawem autorskim, Klient zobowiązany jest do respektowania praw własności intelektualnej Becker Polska w tym zakresie.</li>
                                <li>Becker Polska nie ponosi odpowiedzialności, w granicach obowiązującego prawa, za:</li>
                                <ol type="1">
                                    <li>korzystanie z Portalu w sposób sprzeczny z postanowieniami Regulaminu</li>
                                    <li>szkody powstałe w związku z użytkowaniem Portalu bądź niemożnością jego użytkowania lub w związku z niewłaściwym jego działaniem;</li>
                                    <li>przerwy w dostępności Portalu lub Usług w całości lub części, wynikające z awarii bądź przypadków wadliwego funkcjonowania systemów teleinformatycznych.</li>
                                </ol>
                                <li>W sprawach nieuregulowanych niniejszym Regulaminem stosuje się przepisy Ustawy
                                    o świadczeniu usług drogą elektroniczną oraz Ustawy Kodeksu cywilnego.
                                </li>
                            </ol>

                            <br />
                            Oświadczam, że zapoznałem się z treścią Regulaminu, akceptuję jego postanowienia i zobowiązuje się do jego przestrzegania.
                            <br /><br />
                            Jednocześnie potwierdzam, że składając niniejsze oświadczanie działam jako skutecznie umocowany w tym zakresie reprezentant albo pełnomocnik Klienta korzystającego z Portalu.
                        </Typography>
                    </Typography>
                </Paper>
            </Container>
        </Box>



    )
}