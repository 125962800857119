import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {TotalOutstanding} from "./total-outstanding";
import {PastDue} from "./past-due";
import {CustomerInfo} from "./customer-info";
import {OpenServiceOrders} from "./open-service-orders";
import {HttpClient} from "../../shared/http-client";
import {CustomerDetails} from "./customer-details";
import {OpenServiceOrderRequests} from "./open-service-order-requests";
import Container from "@mui/material/Container";
import {LastServiceInvoices} from "./last-service-invoices";
import {ApplicationSettings} from "../../configuration/application-settings";
import {PortalCircularProgress} from "../../shared/portal-circular-progress";
import {SubstituteDevicesPart} from "./substituteDevicesPart";

export function Dashboard() {
    const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const documentsModuleActive = ApplicationSettings.DocumentsModuleActive;
    const catalogModuleActive = ApplicationSettings.CatalogModuleActive;
    const servicesModuleActive = ApplicationSettings.ServicesModuleActive

    useEffect(() => {
        load();
    }, [])

    function load(){
        setIsLoading(true);

        HttpClient.get<CustomerDetails>(`customers/me`)
            .then(_ => setCustomerDetails(_))
            .catch(_ => { })
            .finally(() =>  setIsLoading(false));
    }
    return (
        <Container>
            <Grid container spacing={3} pb={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <CustomerInfo props={customerDetails!} />
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    <TotalOutstanding props={customerDetails!}/>
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    <PastDue props={customerDetails!}/>
                </Grid>

                {/*<Grid item xs={12} md={6} lg={6}>*/}
                {/*    <OpenQuotes/>*/}
                {/*</Grid>*/}

                {/*<Grid item xs={12} md={6} lg={6}>*/}
                {/*    <OpenOrders/>*/}
                {/*</Grid>*/}

                { servicesModuleActive && <Grid item xs={12} md={6} lg={6}>
                    <OpenServiceOrderRequests />
                </Grid> }

                { servicesModuleActive &&  <Grid item xs={12} md={6} lg={6}>
                    <LastServiceInvoices />
                </Grid> }

                { servicesModuleActive &&  <Grid item xs={12} md={6} lg={6}>
                    <OpenServiceOrders/>
                </Grid> }

                { servicesModuleActive &&  <Grid item xs={12} md={6} lg={6}>
                    <SubstituteDevicesPart />
                </Grid> }
            </Grid>

            <PortalCircularProgress isLoading={isLoading}/>

        </Container>
    )
}