import http from '../shared/http-common';

import { ApplicationSettings } from '../configuration/application-settings'
import {AxiosResponse} from "axios";

export class HttpClient {

    public static async post<TResult>(resource: string, body: string | null): Promise<TResult> {
        const url = ApplicationSettings.API_URL + resource;
        let response: AxiosResponse;

        if (body) {
            response = await http.post(url, body);
        } else {
            response = await http.post(url);
        }

        if (response.status === 200) {
            return Promise.resolve(response.data);
        } else if (response.status === 201) {
            return Promise.resolve(response as any);
        } else if (response.status === 204) {
            return Promise.resolve(response as any);
        } else {
            return Promise.reject(new Error(response.statusText));
        }
    }

    public static async postData<TResult>(resource: string, data: FormData): Promise<TResult> {
        const url = ApplicationSettings.API_URL + resource;
        const response = await http.post(url, data);
        if (response.status === 200) {
            return Promise.resolve(response.data);
        } else if (response.status === 201) {
            return Promise.resolve(response as any);
        } else if (response.status === 200) {
            return Promise.resolve(response as any);
        } else if (response.status === 204) {
            return Promise.resolve(response as any);
        } else {
            return Promise.reject(new Error(response.statusText));
        }
    }

    public static async get<TResult>(resource: string): Promise<TResult> {
        const url = ApplicationSettings.API_URL + resource;
        const response = await http.get(url);
        if (response.status === 200) {
            return Promise.resolve(response.data);
        } else if (response.status === 204) {
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(new Error(response.statusText));
        }
    }

    public static async delete<TResult>(resource: string): Promise<TResult> {
        const url = ApplicationSettings.API_URL + resource;
        const response = await http.delete(url);

        if (response.status === 200) {
            return Promise.resolve(response.data);
        } else if (response.status === 201) {
            return Promise.resolve(response as any);
        } else if (response.status === 204) {
            return Promise.resolve(response as any);
        } else {
            return Promise.reject(new Error(response.statusText));
        }
    }
}