import React, {useEffect, useState} from "react";
import {PagedResponse} from "../../shared/paged-response";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {HttpClient} from "../../shared/http-client";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {StatusChip} from "../../shared/status-chip";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {Button} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";



interface ServiceInvoice {
    id: string;
    slug: string;
    number: string;
    status: string;
    dueDate: Date;
    currencyCode: string;
    amountInclVat: number;
    invoicePdfUrl: string;
}

export function LastServiceInvoices() {
    const[response, setResponse] = useState<PagedResponse<Array<ServiceInvoice>>>();
    const {t} = useTranslation()
    const servicesNs = 'services';
    const openStatusFilter = 'new,pending approval,approved,in process';
    let navigate = useNavigate();

    function load() {
        let requestUrl = `services/service-invoices/my-company?pageNumber=1&pageSize=10&orderBy=document_date_desc`;
        HttpClient.get<PagedResponse<Array<ServiceInvoice>>>(requestUrl)
            .then(_ => setResponse(_))
            .catch(_ => { })
    }


    function ShowAll() {
        navigate(`/services/service-invoices`);
    }

    useEffect(() => {
        load();
    }, [])

    return(
        <Box
            bgcolor="white"
            sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                height: 300,
                border: 1,
                borderColor: "#DDE2E9"
            }}
        >
            <Box bgcolor="primary.main">
                <Typography
                    component="h6"
                    variant="h6"
                    color="white"
                    sx={{p: 1}}
                >
                    {t("dashboard_lastServiceInvoices").toUpperCase()}
                </Typography>
            </Box>
            <Box sx={{p: 0}}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell width="65%"><strong>{t("number", {ns: servicesNs})}</strong></TableCell>
                                <TableCell width="30%"><strong>{t("status", {ns: servicesNs})}</strong></TableCell>
                                <TableCell width="5%"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {response?.data.slice(0,5).map((item) =>
                                <TableRow
                                    key={item.id}
                                    hover={true}
                                >
                                    <TableCell>{item.number}</TableCell>
                                    <TableCell><StatusChip props={{status : item?.status!}}/></TableCell>
                                    <TableCell align="center">
                                        <Button disabled={!item.invoicePdfUrl} onClick={() => window.open(item.invoicePdfUrl)} sx={{padding: 0}}>
                                            <PictureAsPdfIcon/>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container
                      justifyContent="flex-end"
                      padding={1}>
                    <Link underline="none"
                          color="primary"
                          onClick={() => ShowAll()}>
                        {t("viewAll")}
                    </Link>
                </Grid>
            </Box>
        </Box>
    )
}